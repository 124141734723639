import './App.less';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import MainLayout from './layouts';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import React, { useEffect } from 'react';
import localStore from './store/local-store';
import { checkFileExists, getPresignedPutObjectUrl } from './api/file';
import { UploadFileState, UploadPanel, useUploadService } from 'tncet-common';
import LoginRoute from './routes/login';
import { getUsersCheckLogin } from './api/user';
import { User } from './interface/user';
import '@/common/style/table.less';


export const UploadFileContext = React.createContext<UploadFileState>(null);

function App() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let timer$ = setInterval(() => {
            checkLogin();
        }, 60000);
        return (() => {
            clearInterval(timer$);
        })
    }, [])

    const checkLogin = () => {
        if (!localStore.userUuid) {
            if (location.pathname.includes('/login')) {
                return;
            }
        }
        getUsersCheckLogin().then(res => {
            if (res.status === 200) {
                let user: User = res.data;
                localStore.userUuid = user.uuid;
                localStore.setUser(user);
            }
        }).catch(err => {
            localStore.clear();
            navigate("/login");
        })
    }


    return (
        <ConfigProvider locale={zhCN}>
            <UploadFileContext.Provider value={useUploadService()}>
                <div className='tn-base'>
                    <Routes>
                        <Route path='/login' element={<LoginRoute />} />
                        <Route path='/main/*' element={<MainLayout />} />
                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                </div>
                <UploadPanel checkFileExists={checkFileExists} getUploadUrl={getPresignedPutObjectUrl}
                    uploadContext={UploadFileContext} />
            </UploadFileContext.Provider>
        </ConfigProvider>
    )
}

export default App;
