import { Button, Form, Input, InputNumber, Space, Tooltip, Upload, Image, message } from 'antd';
import './edit.less';
import { useEffect, useState, useRef, useContext } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { MinusCircleOutlined, PlusOutlined, MinusSquareOutlined } from '@ant-design/icons';
import { FileUploadParam, UploadFileLocale } from 'tncet-common';
import { UploadFileContext } from '@/App';
import { MINIO_BUCKET_NAME } from '@/constants/file';
import { getDownloadUrl } from '@/api/file';
import { updateMerchant } from '@/api/merchant';
import Merchant from '@/interface/merchant';

const UPLOAD_TYPE = {
    LICENSE: 1,
    LOGO: 2,
    VIDEO: 3,
}

interface IProps {
    merchant: Merchant
    show: boolean
    onClose: () => void
    onSave: () => void
}

export default function EditMerchantPanel(props: IProps) {

    const [form] = Form.useForm();
    const uploadFileContext = useContext(UploadFileContext);
    const [uploadType, setUploadType] = useState<number>(UPLOAD_TYPE.LICENSE);
    const [logoFile, setLogoFile] = useState<any>();
    const [licenseFile, setLicenseFile] = useState<any>();
    const [videoFile, setVideoFile] = useState<any>();
    // const [logoFileMD5, setLogoFileMD5] = useState<string>(null);
    // const [licenseFileMD5, setLicenseFileMD5] = useState<any>();
    // const [videoFileMD5, setVideoFileMD5] = useState<any>();
    const [fileList, setFileList] = useState<any[]>([])   // 图片列表
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (props.show) {
            form.setFieldsValue({
                ...props.merchant
            })
            setLogoFile(props.merchant.logo)
            setLicenseFile(props.merchant.license)
            setVideoFile(props.merchant.video)
        } else {
            form.resetFields();
        }
    }, [props.show])

    const beforeUpload = (file, fileList, uploadType) => {
        if (uploadType === UPLOAD_TYPE.VIDEO) {
            if (videoFile) {
                return false;
            }
            const acceptedVideoTypes = ['video/mp4', 'video/avi', 'video/mkv'];
            let isVideo = file && acceptedVideoTypes.includes(file.type);
            if (!isVideo) {
                message.warning("只能上传视频！");
                return false;
            }
        } else if (uploadType === UPLOAD_TYPE.LOGO) {
            if (logoFile) {
                return false;
            }
            const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/webp'];
            let isImage = file && acceptedImageTypes.includes(file.type);
            if (!isImage) {
                message.warning("只能上传图片！")
                return false;
            }
        } else {
            if (licenseFile) {
                return false;
            }
            const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/webp'];
            let isImage = file && acceptedImageTypes.includes(file.type);
            if (!isImage) {
                message.warning("只能上传图片！")
                return false;
            }
        }
        if (fileList.length !== 0) {
            setFileList([...fileList]);
            setUploadType(uploadType);
        }
        return false;
    }

    useEffect(() => {
        if (!uploadFileContext.visible) {
            if (fileList.length > 0) {
                setFileList([]);
            }
        }
    }, [uploadFileContext.visible])

    useEffect(() => {
        if (fileList.length === 0) return;
        let files: UploadFileLocale[] = fileList.map(item => {
            return {
                file: item,
                callbackFn: (par) => uploadCallbackFn(item, par)
            }
        })
        uploadFileContext.startUpload(files);
    }, [fileList])

    const uploadCallbackFn = (file, uploadFile: FileUploadParam) => {
        return new Promise(resolve => {
            const originFile = {
                ...file,
                md5: uploadFile.md5,
                filePath: uploadFile.filePath,
                bucketName: MINIO_BUCKET_NAME,
                suffix: uploadFile.suffix,
                fileSize: uploadFile.fileSize,
            };
            getDownloadUrl(uploadFile.md5 + uploadFile.suffix).then(res => {
                if (uploadType === UPLOAD_TYPE.LICENSE) {
                    setLicenseFile({ ...originFile, url: res.data, licenseFileMD5: uploadFile.md5 })
                } else if (uploadType === UPLOAD_TYPE.LOGO) {
                    setLogoFile({ ...originFile, url: res.data, logoFileMD5: uploadFile.md5 })
                } else {
                    setVideoFile({ ...originFile, url: res.data, videoFileMD5: uploadFile.md5 })
                }
                resolve(true);
            }).catch(err => {
                message.error("上传图片失败");
                setLogoFile(null);
                resolve(false);
            })
        })
    }

    const onFinish = (values) => {
        if (loading) {
            message.warning("请勿连续点击！");
            return;
        }
        setLoading(true);
        let par = {
            ...values,
            logo: logoFile,
            license: licenseFile,
            video: videoFile,
            logoMd5: logoFile?.logoFileMD5 || null,
            licenseMd5: licenseFile?.licenseFileMD5 || null,
            videoMd5: videoFile?.videoFileMD5 || null,
        }
        console.log('par', par)
        updateMerchant(props.merchant.id, par).then(res => {
            if (res.status === 200) {
                message.success("编辑成功");
                props.onSave();
            }
        }).catch(err => {
            message.error("编辑失败");
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='edit-merchant-container'>
            <div className='edit-merchant-title'>
                <span style={{
                    color: `rgba(0, 0, 0, 0.45)`,
                    cursor: 'pointer'
                }}
                    onClick={props.onClose}>店铺管理</span>
                <span>/</span>
                <span>信息修改</span>
            </div>
            <div className='edit-merchant-panel'>
                <Form
                    form={form}
                    className='edit-merchant-form'
                    labelAlign='right'
                    onFinish={onFinish}
                    layout="vertical"
                    style={{ width: 600 }}
                >
                    <Form.Item label="店铺名称:" name="shopName"
                        rules={[{ required: true, message: '店铺名称不能为空' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="店铺地址:" name="address"
                        rules={[{ required: true, message: '店铺地址不能为空' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="店铺封面:" name="logo" style={{ display: 'flex' }}>
                        <Space>
                            {!logoFile && <Upload beforeUpload={(par1, par2) => beforeUpload(par1, par2, UPLOAD_TYPE.LOGO)} listType="picture-card" showUploadList={false}>
                                上传店铺封面
                            </Upload>}
                            {!!logoFile && <Image width={104} height={104} src={logoFile.url} />}
                            {!!logoFile &&
                                <Tooltip title="删除店铺封面图">
                                    <MinusCircleOutlined style={{ margin: '0 8px' }} onClick={() => setLogoFile(null)} />
                                </Tooltip>}
                        </Space>
                    </Form.Item >

                    {/* <Form.Item label="营业执照:" name="license" style={{ display: 'flex' }}>
                        <Space >
                            {!licenseFile && <Upload beforeUpload={(par1, par2) => beforeUpload(par1, par2, UPLOAD_TYPE.LICENSE)} listType="picture-card" showUploadList={false}>
                                上传营业执照
                            </Upload>}
                            {!!licenseFile && <Image width={104} height={104} src={licenseFile.url} />}
                            {!!licenseFile &&
                                <Tooltip title="删除店铺封面图">
                                    <MinusCircleOutlined style={{ margin: '0 8px' }} onClick={() => setLicenseFile(null)} />
                                </Tooltip>}
                        </Space>
                    </Form.Item > */}
                    <Form.Item label="店铺视频:" name="video" style={{ display: 'flex' }}>
                        <Space >
                            {!videoFile && <Upload beforeUpload={(par1, par2) => beforeUpload(par1, par2, UPLOAD_TYPE.VIDEO)} listType="picture-card" showUploadList={false}>
                                上传店铺视频
                            </Upload>}
                            {!!videoFile && <video width={104} height={104} src={videoFile.url} />}
                            {!!videoFile &&
                                <Tooltip title="删除店铺封面图">
                                    <MinusCircleOutlined style={{ margin: '0 8px' }} onClick={() => setVideoFile(null)} />
                                </Tooltip>}
                        </Space>
                    </Form.Item>
                    <Form.Item label="联系电话:" name="tel"
                        rules={[{ required: true, message: '联系电话不能为空' }, { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的11位手机号' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="接收发货通知短信电话:" name="smsTel"
                        rules={[{ required: true, message: '接收发货通知短信电话不能为空' }, { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的11位手机号' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="店铺描述:" name="description"
                        rules={[{ required: true, message: '店铺描述不能为空' }]}>
                        <TextArea autoSize={{ minRows: 2 }} />
                    </Form.Item>

                    <Space>
                        <Button className='cancel-btn' onClick={props.onClose}>取消</Button>
                        <Button className='submit-btn' htmlType="submit" loading={loading}>保存</Button>
                    </Space>

                </Form>
            </div>
        </div>
    )
}