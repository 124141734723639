import { AxiosResponse } from "axios";
import { axios } from "@/config/axios"
import { AfterSale } from "@/interface/after-sale";


export function getAfterSales(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get("/orders/after-sales", { params: params });
}

export function updateAfterSale(saleId: number, afterSale: AfterSale, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/orders/after-sales/${saleId}`, afterSale, { params: params });
}
