import { Sku } from '@/interface/sku';
import './index.less';
import { useEffect, useState, useRef, useContext } from 'react';
import { message, Modal, Table, Tooltip, Image, Form, Input, Button, Space, Upload, InputNumber } from 'antd';
import { addSku, deleteSkus, getSku, getSkus, updateSku } from '@/api/sku';
import { ReactComponent as EditSvg } from '@/icons/edit.svg';
import TnModal from '@/common/modal';
import { findStatusText, SkuStatus, SkuStatusEnum, SkuStatusList, SpuStatusEnum } from '@/enums/sku-status';
import TextArea from 'antd/lib/input/TextArea';
import { MinusCircleOutlined, PlusOutlined, MinusSquareOutlined } from '@ant-design/icons';
import { UploadFileContext } from '@/App';
import { FileUploadParam, UploadFileLocale } from 'tncet-common';
import { OriginFile } from '@/interface/file';
import { MINIO_BUCKET_NAME } from '@/constants/file';
import { url } from 'inspector';
import { checkFileExists, getDownloadUrl } from '@/api/file';
import { User } from '@/interface/user';
import { addUser, deleteUsers, existUser, getUsers, updateUser } from '@/api/user';
import { UserType, UserTypeText } from '@/enums/user-type';
import localStore from '@/store/local-store';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';


export default function UserPanel() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false)   // 加载状态
    const [selectedSkus, setSelectedSkus] = useState<Sku[]>([])   // 选择的商品列表

    const [user, setUser] = useState<User>(null);    // 登录用户

    const [users, setUsers] = useState<User[]>([])   // 用户列表
    const [displayUsers, setDisplayUsers] = useState<User[]>([]);
    const [editUser, setEditUser] = useState<User>(null);
    const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
    const [isAdd, setIsAdd] = useState<boolean>(false)   // 是否是添加商品

    const [form] = Form.useForm();

    useEffect(() => {
        const user = localStore.getUser();
        if (!user) {
            navigate('/login');
            return;
        }
        setUser(user);
        generateData();
    }, [])

    useEffect(() => {
        setDisplayUsers(users)
    }, [users])

    const generateData = () => {
        getUsers({ notType: UserType.NORMAL }).then(res => {
            const users = res.data
            setUsers(users ?? []);
        }).catch(err => {
            message.error("获取用户列表失败");
        })
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: User[]) => {
        // setSelectedUserIds(newSelectedRowKeys);
        const invalidRows = selectedRows.filter(row => row.type === UserType.ADMIN);

        // 如果存在类型为3的数据，显示提醒信息
        if (invalidRows.length > 0) {
            message.warning('店主账号无法被选中冻结');
            return;
        }

        setSelectedUserIds(selectedRows
            .filter(row => row.type !== UserType.ADMIN)
            .map(row => row.id));
    }

    const onClickDelete = () => {
        if (selectedUserIds.length === 0) {
            message.warning("请选择要冻结的员工账号");
            return;
        }
        TnModal().deleteModal({
            title: '确认冻结',
            content: '确认冻结后会将列表中您所选中的员工账号冻结，冻结后该账号无法登录，确认要冻结吗？',
            onOk() {
                Modal.destroyAll();
                setLoading(true);
                deleteUsers(selectedUserIds).then(res => {
                    message.success("冻结成功");
                    setSelectedUserIds([]);
                    generateData();
                }).catch(err => {
                    message.error("冻结失败");
                }).finally(() => {
                    setLoading(false);
                })
            },
            onCancel() {
                Modal.destroyAll();
            },
        });
    }

    const onClickOk = async () => {
        try {
            const values = await form.validateFields();
            let pwd = values.password;
            let md5Password = CryptoJS.MD5(pwd).toString();
            const newUser = { ...editUser, ...values, password: md5Password };
            if (isAdd) {
                // 请求获取账号是否存在;
                existUser({ mobile: values.mobile }).then(res => {
                    if (res.data == null || res.data) {
                        message.warning("当前账号已存在");
                        return;
                    }
                    setLoading(true);
                    newUser.type = UserType.EMPLOYEE;
                    addUser(newUser).then(res => {
                        message.success("添加成功");
                        generateData();
                    }).catch(err => {
                        message.error("添加失败");
                    }).finally(() => {
                        setEditUser(null);
                        setLoading(false);
                    })
                }).catch(err => {
                    message.error("操作失败");
                    setEditUser(null);
                    return;
                })
            } else {
                setLoading(true);
                updateUser(newUser.uuid, newUser).then(res => {
                    message.success("编辑成功");
                    generateData();
                }).catch(err => {
                    message.error("编辑失败");
                }).finally(() => {
                    setEditUser(null);
                    setLoading(false);
                })
            }

        } catch (err) {
            return;
        }
    }

    const onClickEdit = (user: User) => {
        setEditUser(user);
        form.setFieldsValue({ ...user, password: '' });
        setIsAdd(false);
    }


    return (
        <div className='user-panel'>
            <div className='user-panel-title'></div>
            <div className='user-panel-content'>
                {user?.type === UserType.ADMIN && <div className='user-ope-area'>
                    <div className='sku-panel-title-right'>
                        <div className='sku-pannel-title-btn' onClick={() => {
                            setEditUser({} as User);
                            form.resetFields();
                            setIsAdd(true);
                        }
                        }>添加新员工</div>
                        <div className='sku-pannel-title-btn sku-pannel-title-btn-cancel' onClick={onClickDelete}>批量冻结</div>
                    </div>
                </div>}
                <Table loading={loading} pagination={false} sticky className="user-table" bordered={false}
                    dataSource={displayUsers} rowKey="id"
                    rowSelection={{ onChange: onSelectChange, selectedRowKeys: selectedUserIds }}
                    scroll={{ y: 'calc(100vh - 72px - 16px - 21px - 32px - 16px - 55px - 41px)' }}>
                    <Table.Column title="账号" dataIndex="mobile" ellipsis />
                    <Table.Column title="昵称" dataIndex="petName" ellipsis />
                    <Table.Column title="账号类型" dataIndex="" render={(text, record: User) => {
                        return <div className='user-table-status'>{UserTypeText[record.type]}</div>
                    }} />
                    {!!user && user.type === UserType.ADMIN &&
                        <Table.Column title="操作" dataIndex="" width="160px" render={(text, record: User) => {
                            return (<div className='user-table-action'>
                                <div onClick={() => onClickEdit(record)}>编辑账号</div>
                            </div>)
                        }} />}
                </Table>
            </div>

            <Modal width={550} visible={editUser != null} maskClosable={false} wrapClassName='user-modal'
                onCancel={() => { setEditUser(null); form.resetFields(); }}
                title={isAdd ? '添加账号' : '编辑账号'}
                onOk={onClickOk}>
                {/* {!isAdd && <div className='user-modal-row'>
                    <div className='user-modal-row-text'>账号</div>
                    <div className='user-modal-row-value'>{editUser?.mobile}</div>
                </div>}
                {!isAdd && <div className='user-modal-row'>
                    <div className='user-modal-row-text'>手机号</div>
                    <div className='user-modal-row-value'>{editUser?.telephone}</div>
                </div>} */}
                <Form form={form} className='user-form' labelAlign='right' labelCol={{ span: 4 }}>
                    {isAdd && <Form.Item label="账号" name="mobile"
                        rules={[{ required: true, message: '账号不能为空' },
                        {
                            min: 4,
                            max: 11,
                            message: '账号长度需在4到11个字符之间'
                        },
                        {
                            pattern: /^[^\u4e00-\u9fa5]+$/,
                            message: '账号只能包含英文、数字或符号'
                        }]}>
                        <Input maxLength={11} showCount />
                    </Form.Item>}
                    {isAdd && <Form.Item label="手机号" name="telephone"
                        rules={[
                            {
                                required: true,
                                message: '手机号不能为空',
                            },
                            {
                                pattern: /^1[3-9]\d{9}$/,
                                message: '请输入有效的11位手机号',
                            }]}>
                        <Input maxLength={11} showCount />
                    </Form.Item>}
                    {!isAdd && <Form.Item label="账号" name="mobile"
                        rules={[{ required: true, message: '账号不能为空' },
                        {
                            min: 4,
                            max: 11,
                            message: '账号长度需在4到11个字符之间'
                        },
                        {
                            pattern: /^[^\u4e00-\u9fa5]+$/,
                            message: '账号只能包含英文、数字或符号'
                        }]}>
                        <Input maxLength={11} showCount disabled />
                    </Form.Item>}
                    {!isAdd && <Form.Item label="手机号" name="telephone"
                        rules={[
                            {
                                required: true,
                                message: '手机号不能为空',
                            },
                            {
                                pattern: /^1[3-9]\d{9}$/,
                                message: '请输入有效的11位手机号',
                            }]}>
                        <Input maxLength={11} showCount disabled />
                    </Form.Item>}
                    <Form.Item label="昵称" name="petName"
                        rules={[
                            { required: true, message: '昵称不能为空' },
                            {
                                min: 1,
                                max: 11,
                                message: '昵称长度需在1到11个字符之间'
                            },
                        ]}
                    >
                        <Input maxLength={11} showCount />
                    </Form.Item>
                    <Form.Item label="密码" name="password"
                        rules={[{ required: true, message: '密码不能为空' },
                        {
                            pattern: /^[^\u4e00-\u9fa5]+$/,
                            message: '密码只能包含英文、数字或符号'
                        },
                        {
                            min: 6,
                            max: 18,
                            message: '密码长度需在6到18个字符之间'
                        }]}>
                        <Input maxLength={18} showCount />
                    </Form.Item>
                </Form>
            </Modal>
        </div >
    )
}