import { Modal } from 'antd';
import './index.less'
import classNames from 'classnames';

export interface deleteConfig {
    onOk?: () => void,
    onCancel?: () => void
    closable?: boolean
}

export interface baseConfig {
    onOk?: () => void,
    onCancel?: () => void
    closable?: boolean
    title?: string
    content?: string
    okText?: string
    cancelText?: string
}

export default function TnModal() {

    const deleteModal = (props: baseConfig) => {
        const title = props.title ? props.title : "删除";
        const content = props.content ? props.content : "确认删除后会将列表中您所选中的内容进行删除，建议确认无误后再进行删除，确认要删除吗？";
        const okText = props.okText ? props.okText : "确认";
        const cancelText = props.cancelText ? props.cancelText : "取消";
        const instance = Modal.confirm({
            title: title,
            content: content,
            wrapClassName: classNames({
                'tn-modal-panel': true,
                'closable-hidden': !props.closable
            }),
            // closable: false,
            icon: null,
            onCancel: () => {
                if (props.onCancel) {
                    props.onCancel();
                } else {
                    Modal.destroyAll();
                }
            },
            onOk: () => {
                if (props.onOk) props.onOk();
            },
            okButtonProps: {
                className: 'modal-footer-button danger',
                onClick: () => {
                    if (props.onOk) props.onOk();
                }
            },

            cancelButtonProps: {
                className: 'modal-footer-button second',
                onClick: () => {
                    if (props.onCancel) {
                        props.onCancel();
                    } else {
                        Modal.destroyAll();
                    }
                }
            },
            okText: okText,
            cancelText: cancelText,
        });
        return instance;
    }
    const baseModal = (props: baseConfig) => {
        const title = props.title ? props.title : "删除";
        const content = props.content ? props.content : "确认删除后会将列表中您所选中的内容进行删除，建议确认无误后再进行删除，确认要删除吗？";
        const okText = props.okText ? props.okText : "确认";
        const cancelText = props.cancelText ? props.cancelText : "取消";
        const instance = Modal.confirm({
            title: title,
            content: content,
            wrapClassName: classNames({
                'tn-modal-panel': true,
                'closable-hidden': !props.closable
            }),
            // closable: false,
            icon: null,
            onCancel: () => {
                if (props.onCancel) {
                    props.onCancel();
                } else {
                    Modal.destroyAll();
                }
            },
            onOk: () => {
                if (props.onOk) props.onOk();
            },
            okButtonProps: {
                className: 'modal-footer-button primary',
                onClick: () => {
                    if (props.onOk) props.onOk();
                }
            },

            cancelButtonProps: {
                className: 'modal-footer-button second',
                onClick: () => {
                    if (props.onCancel) {
                        props.onCancel();
                    } else {
                        Modal.destroyAll();
                    }
                }
            },
            okText: okText,
            cancelText: cancelText,
        });
        return instance;
    }

    return {
        baseModal, deleteModal,
    }
}