import { AxiosResponse } from "axios";
import { axios } from "@/config/axios"
import { Sku } from "@/interface/sku";


export function getSkus(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get("/skus", { params: params });
}

export function getSku(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/skus/${id}`, { params: params });
}

export function addSku(sku: Sku, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post("/skus", sku, { params: params });
}

export function deleteSkus(ids: number[]): Promise<AxiosResponse<any>> {
    return axios.post("/skus/multi", ids);
}

export function updateSku(id: number, sku): Promise<AxiosResponse<any>> {
    return axios.put(`/skus/${id}`, sku);
}