import { AxiosResponse } from "axios";
import { axios } from "@/config/axios"

/**
 * 检查文件是否存在
 * @param md5 
 * @param params 
 * @returns 
 */
export function checkFileExists(md5: string, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/files/exists/${md5}`, {
        params: params,
    })
}

/**
 * 获取文件上传地址
 * @param objectName 
 * @param params 
 * @returns 
 */
export function getPresignedPutObjectUrl(objectName: string, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/files/upload/url`, {
        params: {
            objectName: `${objectName}`,
            ...params,
        },
    })
}

export function getDownloadUrl(objectName: string, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/files/download/url`, {
        params: {
            objectName: `${objectName}`,
            ...params,
        },
    })
}