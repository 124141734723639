import LocaleCN from 'antd/es/date-picker/locale/zh_CN'
import 'antd/dist/antd.css'
import { DatePicker } from 'antd'
import * as echarts from 'echarts'
import { useEffect, useRef, useState } from 'react'
import { TimeValue } from '@/interface/order'

// import moment from 'moment'
// import 'moment/locale/zh-cn'
// moment.locale('zh-cn')

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { ChartValue } from '.'
import { CategoryEnum, CategoryEnumText } from '@/enums/category'
dayjs.locale('zh-cn')


interface IProps {
    q_data: ChartValue[]
    setDateRange?: (value) => void
    width: number
    height: number
    activeCategory: number
}

export default function SaleChart(props: IProps) {
    const { RangePicker } = DatePicker

    const [dateRange, setDateRange] = useState<{ start: dayjs.Dayjs, end: dayjs.Dayjs }>(null)
    const chartRef = useRef(null);

    useEffect(() => {
        if (!chartRef.current || !props.width || !props.height)
            return;

        const everydaySales = props.q_data || [];
        // 提取所有的日期，确保 `xAxis` 数据统一
        const allDates = Array.from(new Set(everydaySales.flatMap(series => series.data.map(daySale => daySale.time))));

        // const dates = everydaySales?.map((daySale) => daySale.time) || [];
        // const sales = everydaySales?.map((daySale) => daySale.value) || [];

        // 构建每条线的数据
        const seriesData = everydaySales?.map(cv => ({
            name: cv?.name,
            type: 'line',
            data: allDates.map(date => {
                const dayData = cv?.data?.find(daySale => daySale?.time === date);
                return dayData ? dayData?.value : 0;  // 无数据的日期用0填充
            })
        }));

        echarts.init(chartRef.current).setOption({
            xAxis: {
                type: 'category',
                data: allDates,
            },
            yAxis: {
                type: 'value',
                min: Math.floor(Math.min(...seriesData.flatMap(s => s.data))),
                max: Math.ceil(Math.max(...seriesData.flatMap(s => s.data))),
                axisLabel: {
                    formatter: '{value} 元', // 纵坐标单位
                }
            },
            tooltip: {
                trigger: 'axis', // 悬浮时显示轴上的所有信息
                formatter: params => {
                    return (params as any)?.map(param => `${param.seriesName}: ${param.value} 元`).join('<br/>');
                }
            },
            grid: {
                left: '24px',   // 调整左边距
                right: '24px',  // 调整右边距
                top: '25%',   // 调整上边距
                bottom: '24px', // 调整下边距
                containLabel: true  // 防止标签溢出容器
            },
            series: seriesData,
            legend: {
                data: everydaySales?.map(series => series.name),  // 显示每条线的名称
                orient: 'horizontal',
                ...{ left: '18px', top: '25px' },
                itemGap: 10,
                selected:
                    props.activeCategory == CategoryEnum.ALL ?
                        everydaySales
                            ?.map(series => series.name)
                            .reduce((lines, name) => ({ ...lines, [name]: true }), {})
                        : everydaySales
                            ?.map(series => series.name)
                            .filter(name => name != CategoryEnumText[props.activeCategory])
                            .reduce((lines, name) => ({ ...lines, [name]: false }), { [CategoryEnumText[props.activeCategory]]: true }),
            },
        })
    }, [props.q_data, props.width, props.height, chartRef.current]);

    return <div className='chart'>
        <div ref={chartRef} style={{ width: props.width, height: props.height }}></div>
        <RangePicker locale={LocaleCN} size='small' className='chart-range-picker' style={{ width: '220px', marginLeft: 230 }}
            onChange={
                (dates) => {
                    if (dates == null) {
                        if (props?.setDateRange) {
                            props.setDateRange(null);
                        }
                        return;
                    }
                    // setDateRange({ start: dayjs(dates[0].toDate()), end: dayjs(dates[1].toDate()) })
                    if (props?.setDateRange) {
                        props.setDateRange({ start: dayjs(dates[0].toDate()), end: dayjs(dates[1].toDate()) });
                    }
                }}
        />
        {/* <div className='statistic-data-block'>
            <div className='statistic-data-first'>
                <div style={{ float: 'left' }}>日销售额</div>
                <RangePicker locale={LocaleCN} size='small' style={{ width: '220px', marginLeft: 230 }}
                    onChange={
                        (dates) => {
                            if (dates == null) {
                                if (props?.setDateRange) {
                                    props.setDateRange(null);
                                }
                                return;
                            }
                            // setDateRange({ start: dayjs(dates[0].toDate()), end: dayjs(dates[1].toDate()) })
                            if (props?.setDateRange) {
                                props.setDateRange({ start: dayjs(dates[0].toDate()), end: dayjs(dates[1].toDate()) });
                            }
                        }}
                />
            </div>
            <div style={{ textAlign: "center", marginTop: 0 }}>
                <div ref={chartRef} style={{ height: 330, width: 700 }}></div>
            </div>
        </div> */}
    </div>
}