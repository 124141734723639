import { ReactComponent as Shop1Svg } from '@/icons/dp1.svg';
import { ReactComponent as Shop2Svg } from '@/icons/dp2.svg';
import { ReactComponent as Shop3Svg } from '@/icons/dp3.svg';

import { ReactComponent as Good1Svg } from '@/icons/sku1.svg';
import { ReactComponent as Good2Svg } from '@/icons/sku2.svg';
import { ReactComponent as Good3Svg } from '@/icons/sku3.svg';

import { ReactComponent as Order1Svg } from '@/icons/order1.svg';
import { ReactComponent as Order2Svg } from '@/icons/order2.svg';
import { ReactComponent as Order3Svg } from '@/icons/order3.svg';

import { ReactComponent as AfterSale1Svg } from '@/icons/after1.svg';
import { ReactComponent as AfterSale2Svg } from '@/icons/after2.svg';
import { ReactComponent as AfterSale3Svg } from '@/icons/after3.svg';

import { ReactComponent as SaleStatisticSvg } from '@/icons/sale-statistic.svg';

import { ReactComponent as Staff1Svg } from '@/icons/staff1.svg';
import { ReactComponent as Staff2Svg } from '@/icons/staff2.svg';
import { ReactComponent as Staff3Svg } from '@/icons/staff3.svg';
import { url } from 'inspector';

export const LeftMenu = {
    shop: 1,  // 店铺
    good: 2,  // 商品
    order: 3,  // 订单
    afterSale: 4,  // 售后
    user: 6,  // 员工
}

export const LeftMenus = [
    {
        key: LeftMenu.shop,
        name: '店铺',
        normalIcon: Shop1Svg,
        hoverIcon: Shop2Svg,
        activeIcon: Shop3Svg,
        url: '/main/shop'
    },
    {
        key: LeftMenu.good,
        name: '商品',
        normalIcon: Good1Svg,
        hoverIcon: Good2Svg,
        activeIcon: Good3Svg,
        url: '/main/sku'
    },
    {
        key:
            LeftMenu.order,
        name: '订单',
        normalIcon: Order1Svg,
        hoverIcon: Order2Svg,
        activeIcon: Order3Svg,
        url: '/main/order'
    },
    {
        key: LeftMenu.afterSale,
        name: '售后',
        normalIcon: AfterSale1Svg,
        hoverIcon: AfterSale2Svg,
        activeIcon: AfterSale3Svg,
        url: '/main/after-sale'
    },
    {
        key: LeftMenu.user,
        name: '员工',
        normalIcon: Staff1Svg,
        hoverIcon: Staff2Svg,
        activeIcon: Staff3Svg,
        url: '/main/user'
    },
]