import { Image, message, Modal, Table, Tooltip } from 'antd';
import './index.less';
import { useEffect, useState, useRef } from 'react';
import { AfterSale } from '@/interface/after-sale';
import dayjs from 'dayjs';
import { getAfterSales, updateAfterSale } from '@/api/after-sale';
import { AfterSaleStatus, AfterSaleStatusList, AfterSaleStatusText, AfterSaleType, AfterSaleTypeList, AfterSaleTypeText } from '@/enums/after-sale';
import TnModal from '@/common/modal';
import classNames from 'classnames';
import { ClientType } from '@/enums/client-type';

const OpeType = {
    REJECT: 1,
    PASS_REFUND: 2,
    PASS: 3,
    REFUSE: 4,
    FINISH: 5
}

const OpeTypeTipText = {
    [OpeType.REJECT]: <div className='after-sale-ope-type-tip'>
        <div>确认拒绝售后申请吗？</div><div>拒绝后将立即结束此次售后服务</div>
    </div>,
    [OpeType.PASS_REFUND]: <div className='after-sale-ope-type-tip'>
        <div>确认通过售后申请并退款吗？</div><div>通过后，将立即退款给买家，同时结束此次售后服务</div>
    </div>,
    [OpeType.PASS]: <div className='after-sale-ope-type-tip'>
        <div>确认通过售后申请并等待商品寄回吗？</div><div>通过后，买家需要将商品寄回，等待您确认收货</div>
    </div>,
    [OpeType.REFUSE]: <div>
        <div>确认拒收快递、拒绝退款并结束售后服务吗？</div><div>拒收后将立即结束此次售后服务</div>
    </div>,
    [OpeType.FINISH]: <div className='after-sale-ope-type-tip'>
        <div>确认完成售后服务并立即退款吗？</div><div>完成后将立即退款给买家，同时结束此次售后服务</div>
    </div>
}

export default function AfterSalePanel() {

    const [loading, setLoading] = useState(false);
    const [displayAfterSales, setDisplayAfterSales] = useState([]);
    const [afterSales, setAfterSales] = useState([]);


    useEffect(() => {
        generateData();
    }, [])

    useEffect(() => {
        setDisplayAfterSales(afterSales);
    }, [afterSales])

    const sortAfterSales = (afterSales: AfterSale[]) => {
        return afterSales.sort((a: AfterSale, b: AfterSale) => {

            const inProgressStatuses = [AfterSaleStatus.DOING];

            const aInProgress = inProgressStatuses.includes(a.status);
            const bInProgress = inProgressStatuses.includes(b.status);

            // 优先排序“进行中”状态的订单
            if (aInProgress && !bInProgress) {
                return -1; // a 排在前
            }
            if (!aInProgress && bInProgress) {
                return 1; // b 排在前
            }

            // 如果两者都为“进行中”或都不是，按状态默认顺序排序
            return a.status - b.status;
        })
    }

    const generateData = () => {
        setLoading(true);
        getAfterSales({ clientType: ClientType.WEB }).then(res => {
            setAfterSales(sortAfterSales(res.data || []));
        }).catch(err => {
            message.error("获取售后列表失败");
        }).finally(() => {
            setLoading(false);
        })
    }

    const clickOpe = (afterSale: AfterSale, type: number) => {
        switch (type) {
            case OpeType.REJECT:
                confirm(OpeTypeTipText[type], () => commonOk({ ...afterSale, status: AfterSaleStatus.REJECT }))
                break;
            case OpeType.PASS_REFUND:
                confirm(OpeTypeTipText[type], () => commonOk({ ...afterSale, status: AfterSaleStatus.FINISH }))
                break;
            case OpeType.PASS:
                confirm(OpeTypeTipText[type], () => commonOk({ ...afterSale, status: AfterSaleStatus.DOING }))
                break;
            case OpeType.REFUSE:
                confirm(OpeTypeTipText[type], () => commonOk({ ...afterSale, status: AfterSaleStatus.REFUSE }))
                break;
            case OpeType.FINISH:
                confirm(OpeTypeTipText[type], () => commonOk({ ...afterSale, status: AfterSaleStatus.FINISH }))
                break;
        }
    }

    const commonOk = (afterSale: AfterSale) => {
        setLoading(true);
        updateAfterSale(afterSale.id, afterSale).then(res => {
            message.success("操作成功");
            const newAfterSale = [...afterSales];
            const index = newAfterSale.findIndex(item => item.id === afterSale.id);
            newAfterSale[index] = afterSale;
            setAfterSales(sortAfterSales(newAfterSale || []));
        }).catch(err => {
            message.error("操作失败");
        }).finally(() => {
            setLoading(false);
        })
    }

    const confirm = (content: any, onOk: () => void) => {
        TnModal().deleteModal({
            title: '确认操作',
            content: content,
            onOk() {
                Modal.destroyAll();
                onOk();
            },
            onCancel() {
                Modal.destroyAll();
            },
        });
    }


    function createColRender(generateContent: (record: AfterSale) => any, generateTitle?: (record: AfterSale) => any) {

        return (text, record: AfterSale) =>
            generateTitle ?
                <div className={getClassNames(record)} title={generateTitle(record)}>
                    {generateContent(record)}
                </div>
                : <div className={getClassNames(record)}>
                    {generateContent(record)}
                </div>

        function getClassNames(record: AfterSale) {

            return classNames({
                'ellipsis': true,
                'after-sale-status-doing':
                    orderIsInProgress(record),
                'after-sale-status-done':
                    !orderIsInProgress(record),
            })

            function orderIsInProgress(record: AfterSale): boolean {
                // 售后订单正在进行中吗?
                return [AfterSaleStatus.DOING, AfterSaleStatus.APPLY].includes(record.status)
            }
        }
    }


    return (
        <div className='after-sale-pannel'>
            <div className='after-sale-pannel-table'>
                <Table
                    loading={loading}
                    sticky
                    className="after-sale-table"
                    bordered={false}
                    dataSource={displayAfterSales}
                    rowKey="id"
                    // pagination={{ position: ['bottomCenter'], pageSize: 11 }}
                    pagination={false}
                >
                    <Table.Column title="售后单号" dataIndex="id" width='15%'
                        render={
                            createColRender(record => record.id)
                        }
                    />
                    <Table.Column title="订单号" dataIndex="orderId" width='15%'
                        render={
                            createColRender(record => record.orderId)
                        }
                    />
                    <Table.Column title="商品主图" dataIndex="" width='10%'
                        render={(text, record: AfterSale) => {
                            return <Image src={record.url} width={60} height={60} />
                        }}
                    />
                    <Table.Column title="商品名称" dataIndex="" ellipsis width='10%'
                        render={
                            createColRender(record => record.skuName)
                        }
                    />
                    <Table.Column title="规格名称" dataIndex="" ellipsis width='10%'
                        render={
                            createColRender(record => record.spuName)
                        }
                    />
                    <Table.Column title="单价" dataIndex="" ellipsis width='7%'
                        sorter={(a: AfterSale, b: AfterSale) => a.itemPrice - b.itemPrice}
                        render={
                            createColRender(record => record.itemPrice)
                        }
                    />
                    <Table.Column title="数量" dataIndex="" ellipsis width='7%'
                        sorter={(a: AfterSale, b: AfterSale) => a.amount - b.amount}
                        render={
                            createColRender(record => record.amount)
                        }
                    />
                    <Table.Column title="总价" dataIndex="" ellipsis width='7%'
                        sorter={(a: AfterSale, b: AfterSale) => parseFloat((a.amount * a.itemPrice).toFixed(2)) - parseFloat((b.amount * b.itemPrice).toFixed(2))}
                        render={
                            createColRender(record => (record.amount * record.itemPrice).toFixed(2))
                        }
                    />
                    <Table.Column title="类型" dataIndex="type" ellipsis width='10%'
                        filters={AfterSaleTypeList}
                        onFilter={(value, record: AfterSale) => record.type === value}
                        render={
                            createColRender(
                                record => AfterSaleTypeText[record.type],
                                record => AfterSaleTypeText[record.type],
                            )
                        }
                    />
                    <Table.Column title="状态" dataIndex="" ellipsis width='10%'
                        filters={AfterSaleStatusList}
                        onFilter={(value, record: AfterSale) => record.status === value}
                        render={
                            createColRender(
                                record =>
                                    <span style={{
                                        color: function () {
                                            switch (record.status) {
                                                // 已拒绝
                                                case AfterSaleStatus.REFUSE:
                                                case AfterSaleStatus.REJECT:
                                                    return "#DD0000"
                                                // 已同意
                                                case AfterSaleStatus.FINISH:
                                                    return '#46DD00'
                                                // 已取消
                                                case AfterSaleStatus.CANCEL:
                                                    return 'grey'
                                                // 未操作
                                                default:
                                                    return "#3E7BFA"
                                            }
                                        }()
                                    }}>
                                        {AfterSaleStatusText[record.status]}
                                    </span>,
                                record => AfterSaleStatusText[record.status],
                            )
                        }
                    />
                    <Table.Column title="原因" dataIndex="reason" ellipsis width='10%' />
                    <Table.Column title="退回单号" dataIndex="logisticsNumber" ellipsis width='10%' />
                    <Table.Column title="操作" dataIndex="" width='15%' render={(text, record: AfterSale) => {
                        return <div className='after-sale-ope-btn'>
                            {record.status === AfterSaleStatus.APPLY &&
                                <Tooltip title="拒绝售后申请" showArrow={false}>
                                    <div style={{ color: 'red' }} onClick={() => clickOpe(record, OpeType.REJECT)}>拒绝申请</div>
                                </Tooltip>}
                            {record.status === AfterSaleStatus.APPLY && record.type === AfterSaleType.REFUND &&
                                <Tooltip title="通过售后申请并退款" showArrow={false}>
                                    <div style={{ color: '#46DD00' }} onClick={() => clickOpe(record, OpeType.PASS_REFUND)}>退款</div>
                                </Tooltip>
                            }
                            {record.status === AfterSaleStatus.APPLY && record.type === AfterSaleType.RETURN_AND_REFUND &&
                                <Tooltip title="通过售后申请" showArrow={false}>
                                    <div onClick={() => clickOpe(record, OpeType.PASS)}>通过申请</div>
                                </Tooltip>
                            }
                            {record.status === AfterSaleStatus.DOING &&
                                <Tooltip title="拒收并结束售后" showArrow={false}>
                                    <div style={{ color: 'red' }} onClick={() => clickOpe(record, OpeType.REFUSE)}>拒绝退款</div>
                                </Tooltip>
                            }
                            {record.status === AfterSaleStatus.DOING &&
                                <Tooltip title="通过售后申请并退款" showArrow={false}>
                                    <div style={{ color: '#46DD00' }} onClick={() => clickOpe(record, OpeType.FINISH)}>退款</div>
                                </Tooltip>
                            }
                        </div>
                    }} />
                </Table>
            </div >
        </div >
    )
}
