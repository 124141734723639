import { Order } from '@/interface/order';
import './index.less';
import { useEffect, useState, useRef, forwardRef } from 'react';
import { downloadBillFlow, downloadShippingOrder, getOrders, updateOrder } from '@/api/order';
import { message, Table, DatePicker, Input, Modal, Form, Select, Button, Pagination, Popconfirm } from 'antd';
import { ClientType } from '@/enums/client-type';
import { OrderStatus, OrderStatusList, OrderStatusText } from '@/enums/order-status';
import dayjs from 'dayjs';
import OrderEditPanel from './edit';
import { LogisticsCom, WechatDelivery } from '@/interface/logistics-com';
import { getLogisticsComs, getWeChatLogisticsCompanies } from '@/api/logistics-com';
import classNames from 'classnames';
import TnModal from '@/common/modal';
import { OrderItemStatus } from '@/enums/order-item-status';
const { RangePicker } = DatePicker;

const OrderEditPage = forwardRef(OrderEditPanel);

export default function OrderPanel() {
    const orderEditRef = useRef(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(30);

    // 过滤条件
    const [phone, setPhone] = useState('');
    const [logisticsNum, setLogisticsNum] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [createTime, setCreateTime] = useState<string[]>(['', '']);

    const [orders, setOrders] = useState<Order[]>([]);
    const [displayOrders, setDisplayOrders] = useState<Order[]>([]);
    const [paginatedOrders, setPaginatedOrders] = useState<Order[]>([]);

    const [loading, setLoading] = useState(false);
    const [detailOrder, setDetailOrder] = useState<Order>(null);

    const [isShowEdit, setIsShowEdit] = useState(false);
    const [isShowLogistics, setIsShowLogistics] = useState(false); // 是否显示发货弹窗

    // const [logisticsCom, setLogisticsCom] = useState<LogisticsCom[]>([]); // 物流公司
    const [deliverys, setDeliverys] = useState<WechatDelivery[]>([]); // 物流公司

    const [selectLogisticsCode, setSelectLogisticsCode] = useState(''); // 选中的物流公司
    const [selectLogisticsName, setSelectLogisticsName] = useState(''); // 选中的物流公司名称
    const [logisticsNumInput, setLogisticsNumInput] = useState(''); // 输入的物流单号

    const [filteredValue, setFilteredValue] = useState([]);
    const [sortOrder, setSortOrder] = useState(null);

    const [exportLoading, setExportLoading] = useState<boolean>(false);

    useEffect(() => {
        generateData();
        getWeChatLogisticsCompanies().then(res => {
            // setLogisticsCom(res.data);
            setDeliverys(res.data);
        }).catch(err => {
            message.error("获取物流公司失败");
        })
    }, [])

    useEffect(() => {
        filterDisplayOrders();
    }, [orders, orderNo, logisticsNum, phone, createTime])

    // useEffect(() => {

    // }, [currentPage, pageSize])

    const generateData = () => {
        setLoading(true);
        getOrders({ clientType: ClientType.WEB }).then(res => {
            setOrders(sortOrders(res.data || []));
        }).catch(err => {
            message.error("获取订单列表失败");
        }).finally(() => {
            setLoading(false);
        })
    }

    const onPageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);

        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedOrders = displayOrders?.slice(startIndex, endIndex);
        setPaginatedOrders([...paginatedOrders]);
    }

    const sortOrders = (orders: Order[]) => {
        orders.sort((a: Order, b: Order) => {
            const groupA = [OrderStatus.SUCCESS, OrderStatus.WAITING_RECEIVE]; // 前面的组
            const aInGroupA = groupA.includes(a.status);
            const bInGroupA = groupA.includes(b.status);
            // 如果 a 在 groupA 且 b 不在 groupA，a 排在前
            if (aInGroupA && !bInGroupA) {
                return -1;
            }
            // 如果 b 在 groupA 且 a 不在 groupA，b 排在前
            if (!aInGroupA && bInGroupA) {
                return 1;
            }
            // 如果两者都在同一组内，则按状态升序排列
            return a.status - b.status;
        });
        return orders;
    };

    const filterDisplayOrders = () => {
        const displays = [];
        orders.forEach(order => {
            const logisticsNumber = order.orderItems[0].logisticsNumber;

            // 条件判断，空字符串时跳过该条件的筛选
            const isOrderNoMatch = !orderNo || order.id.includes(orderNo);
            const isLogisticsNumMatch = !logisticsNum || logisticsNumber?.includes(logisticsNum);
            const isPhoneMatch = !phone || order.tel.includes(phone);
            const startTime = createTime[0];
            const endTime = createTime[1];

            const orderTime = new Date(order.gmtCreate).getTime();
            const isStartTimeMatch = !startTime || orderTime >= new Date(startTime).getTime();
            const isEndTimeMatch = !endTime || orderTime <= new Date(endTime).getTime();

            // 只有在满足非空条件的情况下才将订单加入结果数组
            if (isOrderNoMatch && isLogisticsNumMatch && isPhoneMatch && isStartTimeMatch && isEndTimeMatch) {
                displays.push(order);
            }
        });
        setDisplayOrders([...displays]);
        // 分页处理
        const startIndex = 0;
        const endIndex = startIndex + pageSize;
        const paginatedOrders = displays?.slice(startIndex, endIndex);
        setPaginatedOrders([...paginatedOrders]);
        setCurrentPage(1);
    };

    const clickToLogistics = (order: Order) => {
        const items = order.orderItems;
        let logisticsNumber = "";
        let logisticsComName = "";
        let logisticsComCode = "";
        if (items && items.length > 0) {
            logisticsNumber = items[0].logisticsNumber;
            logisticsComName = items[0].logisticsComName;
            logisticsComCode = items[0].logisticsComCode;
        }
        setDetailOrder(order);
        setIsShowLogistics(true);
        setSelectLogisticsName(logisticsComName);
        setSelectLogisticsCode(logisticsComCode);
        setLogisticsNumInput(logisticsNumber);
    }

    const clickToConfirm = () => {
        if (logisticsNumInput == "") {
            message.warning("快递单号不能为空");
            return;
        }
        if (selectLogisticsCode == "" || selectLogisticsName == "") {
            message.warning("快递公司不能为空");
            return;
        }
        const logisticsNumber = logisticsNumInput;
        // if (!(detailOrder?.status === OrderStatus.SUCCESS || detailOrder?.status === OrderStatus.WAITING_RECEIVE)) {
        if (!(detailOrder?.status === OrderStatus.SUCCESS)) {
            message.warning("当前订单状态不允许修改快递单号");
            setIsShowLogistics(false);
            return;
        }
        if (logisticsNumber == null || logisticsNumber == '') {
            // 当前订单大于待发货状态，则说明已经发过货，可以修改单号，但是不能改为空
            if (detailOrder.status > OrderStatus.SUCCESS) {
                message.warning("已发货的订单,快递单号不能为空");
                return;
            }
            return;
        }
        if (detailOrder.status >= OrderStatus.RECEIVED) {
            message.warning("已收货订单不允许再修改快递单号");
            setIsShowLogistics(false);
            return;
        }
        const orderItems = detailOrder.orderItems;
        if (orderItems == null || orderItems.length === 0) {
            message.warning("订单信息有误");
            setIsShowLogistics(false);
            return;
        }
        // 判断单号是否重复
        const num = orderItems[0].logisticsNumber;
        const code = orderItems[0].logisticsComCode;
        if (num == logisticsNumber && code == selectLogisticsCode) {
            // 单号一致不需要修改状态
            message.success("快递单号填写成功");
            setIsShowLogistics(false);
            return;
        }
        orderItems.forEach(item => {
            item.logisticsNumber = logisticsNumber;
            item.logisticsComCode = selectLogisticsCode;
            item.logisticsComName = selectLogisticsName;
        });
        if (detailOrder.status == OrderStatus.SUCCESS || detailOrder.status == OrderStatus.WAITING_RECEIVE) {
            detailOrder.status = OrderStatus.WAITING_RECEIVE;
            detailOrder.orderItems = orderItems;
            detailOrder.deliveryOpe = true;
        }
        setLoading(true);
        updateOrder(detailOrder.id, detailOrder).then(() => {
            message.success("快递单号填写成功");
            // 刷新订单列表
            const idx = orders.findIndex(item => item.id === detailOrder.id);
            if (idx !== -1) {
                orders[idx] = detailOrder;
                setOrders(sortOrders([...orders]));
            }
            setIsShowLogistics(false);
        }).catch(err => {
            message.error("快递单号填写失败");
        }).finally(() => {
            setLoading(false);
        });
    }

    const exportShippingOrder = () => {
        setExportLoading(true);
        downloadShippingOrder().then(res => {
            if (res) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                // 创建a标签，为了弹出下载框
                const link = document.createElement('a');
                link.style.display = 'none';
                // 设置a标签路径
                link.href = url;
                // 设置文件名
                const timeStr = dayjs().format("YYYY-MM-DD");
                link.download = timeStr + ' 发货单.xlsx';
                document.body.appendChild(link);
                link.click();
                // 释放 URL对象
                URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
            }
        }).catch(err => {
            message.error("导出发货单失败")
        }).finally(() => {
            setExportLoading(false);
        })
    }

    const exportBillFlow = () => {
        setExportLoading(true);
        downloadBillFlow().then(res => {
            if (res) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                // 创建a标签，为了弹出下载框
                const link = document.createElement('a');
                link.style.display = 'none';
                // 设置a标签路径
                link.href = url;
                // 设置文件名
                const timeStr = dayjs().format("YYYY年MM月");
                link.download = timeStr + '账单流水.xlsx';
                document.body.appendChild(link);
                link.click();
                // 释放 URL对象
                URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
            }
        }).catch(err => {
            message.error("导出账单流水失败")
        }).finally(() => {
            setExportLoading(false);
        })
    }



    return (
        <div className='order-panel'>
            <div className='order-panel-title'>
                {!isShowEdit &&
                    <div className='order-panel-title-left'>
                        <div className='order-panel-title-left-row'>
                            <div className='order-panel-title-left-row-title'>订单编号</div>
                            <Input value={orderNo} onChange={(e) => setOrderNo(e.target.value)} />
                        </div>
                        <div className='order-panel-title-left-row'>
                            <div className='order-panel-title-left-row-title'>物流单号</div>
                            <Input value={logisticsNum} onChange={(e) => setLogisticsNum(e.target.value)} />
                        </div>
                        <div className='order-panel-title-left-row'>
                            <div className='order-panel-title-left-row-title'>收货人手机号</div>
                            <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div className='order-panel-title-left-row'>
                            <div className='order-panel-title-left-row-title'>订单创建时间</div>
                            <RangePicker onChange={(date, datestring) => setCreateTime([...datestring])} />
                        </div>
                        <Button type='primary' loading={exportLoading} onClick={exportShippingOrder}>导出发货单</Button>
                        <Button type='primary' loading={exportLoading} onClick={exportBillFlow}>导出当月流水</Button>
                    </div>}
                {/* <span style={{ color: isShowEdit ? 'rgba(0, 0, 0, 0.45)' : '#000', cursor: 'pointer' }}
                    onClick={() => setIsShowEdit(false)}>订单管理</span>
                {isShowEdit && <span style={{ cursor: 'default' }}>/</span>}
                {isShowEdit && <span style={{ cursor: 'pointer' }}>订单信息</span>} */}

                {isShowEdit && <div className='sku-panel-title-right'>
                    {detailOrder.status === OrderStatus.SUCCESS &&
                        <div className='sku-pannel-title-btn sku-pannel-title-btn-cancel'
                            onClick={() => { clickToLogistics(detailOrder) }}>发货</div>}
                    {/* <div className='sku-pannel-title-btn' onClick={clickOk}>确认</div> */}
                    <div className='sku-pannel-title-btn sku-pannel-title-btn-cancel'
                        onClick={() => setIsShowEdit(false)}>返回列表</div>
                </div>}
            </div>
            {!isShowEdit && <>
                <div className='order-panel-content'>
                    <Table
                        loading={loading}
                        pagination={false}
                        sticky
                        className="common-table"
                        bordered={false}
                        onChange={(e, filter, sorter) => {
                            onPageChange(1, pageSize);
                            setFilteredValue(filter.status)
                            setSortOrder(sorter['order']);
                        }}
                        dataSource={paginatedOrders}
                        rowKey="id"
                    >
                        <Table.Column title="订单编号" dataIndex="id"
                            render={(text, record: Order) => {
                                return <div className={classNames({
                                    'ellipsis': true,
                                    'order-status-doing': ![OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                    'order-status-close': [OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                })}>{text}</div>
                            }}
                        />
                        <Table.Column title="订单创建时间" dataIndex="gmtCreate" width='10%'
                            sortOrder={sortOrder}
                            sorter={(a: Order, b: Order) => a.gmtCreate - b.gmtCreate}
                            render={(text, record: Order) => {
                                return <div className={classNames({
                                    'ellipsis': true,
                                    'order-status-doing': ![OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                    'order-status-close': [OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                })}>{dayjs(record.gmtCreate).format("YYYY-MM-DD HH:mm:ss")}</div>
                            }} />
                        <Table.Column title="订单状态" width='10%'
                            dataIndex="status"
                            filters={
                                [
                                    { text: '未支付', value: OrderStatus.NONE },

                                    { text: '已关闭', value: OrderStatus.CLOSE },

                                    { text: '待发货', value: OrderStatus.SUCCESS },
                                    { text: '待收货', value: OrderStatus.WAITING_RECEIVE },
                                    { text: '已收货', value: OrderStatus.RECEIVED },
                                ]
                            }
                            filteredValue={filteredValue}
                            onFilter={
                                (value, record: Order) =>
                                    value === OrderStatus.NONE
                                        ? [OrderStatus.NONE, OrderStatus.DURING, OrderStatus.FAILURE].includes(record.status)
                                        : record.status === value
                            }
                            render={
                                (text, record: Order) => {
                                    return <div
                                        className={
                                            classNames({
                                                'order-status-doing': ![OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                                'order-status-close': [OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                            })
                                        }
                                        style={{
                                            color: function () {
                                                switch (record.status) {
                                                    case OrderStatus.NONE:
                                                    case OrderStatus.DURING:
                                                    case OrderStatus.FAILURE:
                                                    case OrderStatus.CLOSE:
                                                        return 'grey'
                                                    case OrderStatus.SUCCESS:
                                                        return 'orange'
                                                    case OrderStatus.WAITING_RECEIVE:
                                                        return '#3E7BFA'
                                                    case OrderStatus.RECEIVED:
                                                        return '#46DD00'
                                                }
                                            }()
                                        }}
                                    >
                                        {OrderStatusText[record.status]}
                                    </div>
                                }
                            }
                        />
                        <Table.Column title="存在售后商品" dataIndex="" width='7%'
                            render={(text, record: Order) => {
                                return <div className={classNames({
                                    'ellipsis': true,
                                    'order-status-doing': ![OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                    'order-status-close': [OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                })}>{record?.orderItems?.some(oi => oi.status !== OrderItemStatus.NORMAL) ? '是' : '/'}</div>
                            }}
                        />
                        <Table.Column title="收货人" dataIndex="fullName" width='5%'
                            render={(text, record: Order) => {
                                return <div className={classNames({
                                    'ellipsis': true,
                                    'order-status-doing': ![OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                    'order-status-close': [OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                })}>{text}</div>
                            }}
                        />
                        <Table.Column title="订单总价" dataIndex="totalPrice" width='6%'
                            render={(text, record: Order) => {
                                return <div className={classNames({
                                    'ellipsis': true,
                                    'order-status-doing': ![OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                    'order-status-close': [OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                })}>{Number(text)?.toFixed(2) || ""}</div>
                            }}
                        />
                        <Table.Column title="联系方式" dataIndex="tel" width='7%' render={(text, record: Order) => {
                            return <div className={classNames({
                                'ellipsis': true,
                                'order-status-doing': ![OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                'order-status-close': [OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                            })}>{text}</div>
                        }} />
                        <Table.Column title="收货地址" dataIndex="" ellipsis width='10%' render={(text, record: Order) => {
                            return <div title={record.province +
                                (record.city !== record.province ? record.city : '') + record.county} className={classNames({
                                    'ellipsis': true,
                                    'order-status-doing': ![OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                    'order-status-close': [OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                })}>{record.province}
                                {record.city !== record.province ? record.city : ''}
                                {record.county}</div>
                        }} />
                        <Table.Column title="详细地址" dataIndex="specificLocation" render={(text, record: Order) => {
                            return <div className={classNames({
                                'ellipsis': true,
                                'order-status-doing': ![OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                'order-status-close': [OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                            })}>{text}</div>
                        }} />
                        <Table.Column title="备注" dataIndex="remark" width='10%' render={(text, record: Order) => {
                            return <div className={classNames({
                                'ellipsis': true,
                                'order-status-doing': ![OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                                'order-status-close': [OrderStatus.CLOSE, OrderStatus.RECEIVED, OrderStatus.FAILURE].includes(record.status),
                            })}>{text}</div>
                        }} />
                        <Table.Column title="操作" dataIndex="" width='10%' fixed='right' render={(text, record: Order) => {
                            return <div className='common-table-action'>
                                <div onClick={() => { setDetailOrder(record); setIsShowEdit(true) }}>查看详情</div>
                                {record.status === OrderStatus.SUCCESS && <div onClick={() => clickToLogistics(record)}>发货</div>}
                            </div>
                        }} />
                    </Table>
                </div>
                <div className='order-panel-pagi'>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={displayOrders?.filter(item => {
                            if (!filteredValue || filteredValue?.length === 0) return true;
                            return filteredValue?.some(fv => fv === item?.status)
                        })?.length}
                        showTotal={total => `共${total}条`}
                        pageSizeOptions={[10, 30, 50, 100]}
                        onChange={onPageChange}
                        hideOnSinglePage={false}
                        showSizeChanger={true}
                    />
                </div>
            </>}
            {isShowEdit && <OrderEditPage ref={orderEditRef} orderId={detailOrder?.id} />}
            <Modal open={isShowLogistics} title="发货"
                onCancel={() => setIsShowLogistics(false)} wrapClassName='logistics-modal'
                footer={<div className='logistics-modal-footer'>
                    <Popconfirm
                        title="单号填写后不能修改，确认物流公司和单号正确吗？"
                        onConfirm={clickToConfirm}
                        onCancel={() => { }}
                        okText="确认"
                        cancelText="取消"
                        placement="bottom"
                    >
                        <div className='logistics-modal-footer-default'
                        // onClick={clickToConfirm}
                        >发货</div>
                    </Popconfirm>

                    <div className='logistics-modal-footer-default logistics-modal-footer-cancel'
                        onClick={() => setIsShowLogistics(false)}>取消</div>
                </div>}>
                <div className='logistics-modal-form-row'>

                    <div className='logistics-modal-form-row-label'>物流公司</div>
                    <div className='logistics-modal-form-row-value'>
                        <Select
                            value={selectLogisticsName}
                            showSearch
                            allowClear
                            onChange={(value, option) => {
                                console.log("option", option);
                                if (!option) {
                                    setSelectLogisticsCode('');
                                    setSelectLogisticsName('');
                                } else {
                                    setSelectLogisticsCode(option['key']);
                                    setSelectLogisticsName(option['children']);
                                }
                            }}
                            filterOption={(input, option) => {
                                return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase());
                            }}
                        >
                            {deliverys?.map(com => {
                                return <Select.Option key={com.deliveryId} value={com.deliveryId}>{com.deliveryName}</Select.Option>
                            })}
                        </Select>
                    </div>
                </div>
                <div className='logistics-modal-form-row'>
                    <div className='logistics-modal-form-row-label'>快递单号</div>
                    <div className='logistics-modal-form-row-value'>
                        <Input value={logisticsNumInput} onChange={(e) => setLogisticsNumInput(e.target.value)} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}
