import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import './index.less'
import { Sku } from '@/interface/sku';
import { getSku } from '@/api/sku';
import { Button, Form, Image, Input, InputNumber, message, Radio, Select, Skeleton, Space, Tooltip, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { MinusCircleOutlined, PlusOutlined, MinusSquareOutlined, UpCircleOutlined } from '@ant-design/icons';
import { MINIO_BUCKET_NAME } from '@/constants/file';
import { FileUploadParam, UploadFileLocale } from 'tncet-common';
import { getDownloadUrl } from '@/api/file';
import { UploadFileContext } from '@/App';
import { CategoryEnum, CategoryEnumOptions, CategoryEnumText } from '@/enums/category';
import { DetailType } from '@/enums/detail-type';


interface IProps {
    skuId: number;
}

export default function SkuEditPanel(props: IProps, ref) {
    const { skuId } = props;

    const uploadFileContext = useContext(UploadFileContext);

    const [editSku, setEditSku] = useState<Sku>(null);
    const [loading, setLoading] = useState(false);

    const [fileList, setFileList] = useState<any[]>([])   // 图片列表
    const [detailFileList, setDetailFileList] = useState<any[]>([])   // 详情图片列表
    const [bannerFileList, setBannerFileList] = useState<any[]>([])   // banner图片列表

    const [mainFile, setMainFile] = useState<any>(null)   // 主图
    const [detailFiles, setDetailFiles] = useState<any[]>([])   // 详情图片列表
    const [bannerFiles, setBannerFiles] = useState<any[]>([])   // banner图片列表

    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
        form: form, mainFile: mainFile, detailFiles: detailFiles,
        bannerFiles: bannerFiles, editSku: editSku
    }));

    useEffect(() => {
        if (!skuId) {
            setEditSku({} as Sku);
            form.resetFields();
            return;
        }
        setLoading(true);
        getSku(skuId).then(res => {
            const sku = res.data;
            setEditSku(sku);
            setMainFile(sku.originFile);
            setDetailFiles(sku.details || []);
            setBannerFiles(sku.banners || [])
            form.setFieldsValue(sku);
        }).catch(err => {
            message.error("获取商品详情失败");
        }).finally(() => {
            setLoading(false);
        })
    }, [skuId])

    useEffect(() => {
        if (fileList.length === 0) return;
        let files: UploadFileLocale[] = fileList.map(item => {
            return {
                file: item,
                callbackFn: (par) => uploadCallbackFn(item, par)
            }
        })
        uploadFileContext.startUpload(files)
    }, [fileList])

    useEffect(() => {
        if (detailFileList.length === 0) return;
        let files: UploadFileLocale[] = detailFileList.map(item => {
            return {
                file: item,
                callbackFn: (par) => uploadDetailCallbackFn(item, par)
            }
        })
        uploadFileContext.startUpload(files)
    }, [detailFileList])

    useEffect(() => {
        if (bannerFileList.length === 0) return;
        let files: UploadFileLocale[] = bannerFileList.map(item => {
            return {
                file: item,
                callbackFn: (par) => uploadDetailCallbackFn(item, par, true)
            }
        })
        uploadFileContext.startUpload(files)
    }, [bannerFileList])

    useEffect(() => {
        if (!uploadFileContext.visible) {
            if (fileList.length > 0) {
                setFileList([]);
            }
            if (detailFileList.length > 0) {
                setDetailFileList([]);
            }
            if (bannerFileList.length > 0) {
                setBannerFileList([]);
            }
        }
    }, [uploadFileContext.visible])


    const beforeUpload = (file, fileList) => {
        if (fileList.length !== 0) {
            setFileList([...fileList]);
        }
        return false;
    }

    const beforeDetailUpload = (file, fileList) => {
        if (fileList.length !== 0) {
            setDetailFileList([...fileList]);
        }
        return false;
    }

    const beforeBannerUpload = (file, fileList) => {
        if (fileList.length !== 0) {
            setBannerFileList([...fileList]);
        }
        return false;
    }

    const uploadCallbackFn = (file, uploadFile: FileUploadParam) => {
        return new Promise(resolve => {
            const originFile = {
                ...file,
                md5: uploadFile.md5,
                filePath: uploadFile.filePath,
                bucketName: MINIO_BUCKET_NAME,
                suffix: uploadFile.suffix,
                fileSize: uploadFile.fileSize,
            };
            getDownloadUrl(uploadFile.md5 + uploadFile.suffix).then(res => {
                setMainFile({ ...originFile, url: res.data });
                resolve(true);
            }).catch(err => {
                message.error("上传图片失败");
                setMainFile(null);
                resolve(false);
            })
        })
    }

    const uploadDetailCallbackFn = (file, uploadFile: FileUploadParam, isBanner = false) => {
        return new Promise(resolve => {
            const originFile = {
                ...file,
                md5: uploadFile.md5,
                filePath: uploadFile.filePath,
                bucketName: MINIO_BUCKET_NAME,
                suffix: uploadFile.suffix,
                fileSize: uploadFile.fileSize,
                type: isBanner ? DetailType.BANNER : DetailType.DETAIL
            };
            getDownloadUrl(uploadFile.md5 + uploadFile.suffix).then(res => {
                if (isBanner) {
                    setBannerFiles(prev => {
                        return [...prev, { ...originFile, url: res.data }];
                    });
                } else {
                    setDetailFiles(prev => {
                        return [...prev, { ...originFile, url: res.data }];
                    });
                }
                resolve(true);
            }).catch(err => {
                message.error("上传图片失败");
                resolve(false);
            })
            resolve(true);
        })
    }

    const onDelImage = (file, isDetail = true, isBanner = false) => {
        if (isDetail) {
            const uid = file.uid || file.id; // 使用 uid，如果 uid 为空则使用 id

            if (isBanner) {
                const news = bannerFiles.filter(item => item.uid !== uid && item.id !== uid); // 过滤条件增加 id 检查
                setBannerFiles(news);
            } else {
                const news = detailFiles.filter(item => item.uid !== uid && item.id !== uid); // 同样增加 id 检查
                setDetailFiles(news);
            }
        } else {
            setMainFile(null);
        }
    };


    return <div className='edit-sku-panel'>
        <Skeleton loading={loading}>
            <div className='edit-sku-col-part'>
                <div className='edit-sku-col-part-title'>商品信息</div>
                <div className='edit-sku-col-part-content'>
                    <Form form={form} className='edit-sku-form' labelAlign='right' layout='vertical'>
                        <Form.Item label='商品分类' name="categoryId"
                            rules={[{ required: true, message: '商品分类不能为空' }]}>
                            {/* <Select options={CategoryEnumOptions} style={{ width: '210px' }} /> */}
                            <Radio.Group>
                                <Space size={48}>
                                    {CategoryEnumOptions.map((item, key) => {
                                        if (item.value === CategoryEnum.ALL) return <></>
                                        return <Radio key={key} value={item.value}>{item.label}</Radio>
                                    })}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="商品名称" name="name"
                            rules={[{ required: true, message: '商品名称不能为空' }]}>
                            <Input maxLength={255} showCount />
                        </Form.Item>
                        <Form.Item label="商品描述" name="description"
                            rules={[{ required: true, message: '商品描述不能为空' }]}>
                            <TextArea autoSize={{ minRows: 2 }} />
                        </Form.Item>
                        <Form.Item label="物流描述" name="logisticsDesc"
                            rules={[{ required: true, message: '物流描述不能为空' }]}>
                            <Input maxLength={255} showCount placeholder='例如：山西忻州 3天内发货' />
                        </Form.Item>
                        <Form.Item label="商品封面图" name="img" style={{ display: 'flex' }} required>
                            <Space >
                                {!mainFile && <Upload beforeUpload={beforeUpload} listType="picture-card" showUploadList={false}>
                                    上传商品主图
                                </Upload>}
                                {mainFile && <Image width={102} height={102} src={mainFile.url} />}
                                {/* <Upload beforeUpload={beforeUpload} listType="picture-card" showUploadList={false}>
                                    {!!mainFile ? <Image width={102} height={102} src={mainFile.url} /> :
                                        '上传商品主图'}
                                </Upload> */}
                                {!!mainFile &&
                                    <Tooltip title="删除主图">
                                        <MinusCircleOutlined style={{ margin: '0 8px' }} onClick={() => onDelImage(mainFile, false)} />
                                    </Tooltip>}
                            </Space>
                        </Form.Item >
                        <Form.Item label="商品轮播图" name="banners" required>
                            <Space align='center' wrap>
                                {bannerFiles?.map((file, index) => {
                                    return <Space key={index} align='center'>
                                        <Image width={102} height={102} src={file.url} />
                                        <Tooltip title="删除轮播图">
                                            <MinusCircleOutlined style={{ margin: '0 8px' }} onClick={() => onDelImage(file, true, true)} />
                                        </Tooltip>
                                    </Space>
                                })}
                                <Upload beforeUpload={beforeBannerUpload} listType="picture-card" style={{ height: '102px', width: '102px' }}
                                    showUploadList={false}>
                                    上传商品轮播图
                                </Upload>
                            </Space>
                        </Form.Item>
                        <Form.Item label="商品详情图" name="details" required>
                            <Space align='center' wrap>
                                {detailFiles?.map((file, index) => {
                                    return <Space key={index} align='center'>
                                        <Image width={102} height={102} src={file.url} />
                                        <Tooltip title="删除详情图">
                                            <MinusCircleOutlined style={{ margin: '0 8px' }} onClick={() => onDelImage(file)} />
                                        </Tooltip>
                                    </Space>
                                })}
                                <Upload beforeUpload={beforeDetailUpload} listType="picture-card" style={{ height: '102px', width: '102px' }}
                                    showUploadList={false}>
                                    上传商品详情图
                                </Upload>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <div className='edit-sku-col-divide' />
            <div className='edit-sku-col-part'>
                <div className='edit-sku-col-part-title' style={{ marginLeft: 120 }}>销售信息</div>
                <div className="edit-sku-col-part-content" style={{ margin: '0 120px', display: 'flex' }}>
                    <span style={{ width: 250 + 24 }}>参数名</span>
                    <span style={{ width: 250 }}>参数值</span>
                </div>
                <div className='edit-sku-col-part-content'>
                    <Form
                        form={form}
                        className='sku-form'
                        labelAlign='right'
                        layout='vertical'
                        style={{ marginLeft: 120, paddingRight: 120 }}
                    >
                        <Form.List name="params">
                            {(fields, { add, remove, move }) => (
                                <>
                                    {fields.map((field, idx) => (
                                        <Space key={field.key} align="start" size='large'>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) =>
                                                    prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                }
                                            >
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        // label="参数名"
                                                        name={[field.name, 'paramKey']}
                                                        rules={[{ required: true, message: '商品参数名不能为空' }]}
                                                    >
                                                        <Input maxLength={255} showCount style={{ width: 250 }} />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                // label="参数值"
                                                name={[field.name, 'value']}
                                                rules={[{ required: true, message: '商品参数值不能为空' }]}
                                            >
                                                <Input maxLength={255} showCount style={{ width: 250 }} />
                                            </Form.Item>
                                            <MinusCircleOutlined style={{ marginTop: 8 }} onClick={() => remove(field.name)} />
                                            {
                                                idx !== 0
                                                && <UpCircleOutlined style={{ marginTop: 8 }} onClick={() => {
                                                    if (idx === 0)
                                                        return
                                                    move(idx, idx - 1)
                                                }} />
                                            }
                                        </Space>
                                    ))}

                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            添加商品参数
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Form.List name="spus">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(field => (
                                        <Space key={field.key} align="center">
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) =>
                                                    prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                }
                                            >
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="规格名"
                                                        name={[field.name, 'name']}
                                                        rules={[{ required: true, message: '商品规格名不能为空' }]}
                                                    >
                                                        <Input style={{ width: '200px' }} maxLength={255} showCount />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                label="价格(元)"
                                                name={[field.name, 'price']}
                                                rules={[{ required: true, message: '商品规格价格不能为空' }]}
                                            >
                                                <InputNumber precision={2} min={0.01} style={{ width: '140px' }} />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                label="库存量"
                                                name={[field.name, 'stock']}
                                                rules={[{ required: true, message: '商品规格库存量不能为空' }]}
                                            >
                                                <InputNumber min={1} style={{ width: '120px' }} />
                                            </Form.Item>

                                            <MinusCircleOutlined style={{ marginTop: '12px' }} onClick={() => remove(field.name)} />
                                        </Space>
                                    ))}

                                    {fields?.length < 1 && <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            添加规格
                                        </Button>
                                    </Form.Item>}
                                </>
                            )}
                        </Form.List>
                    </Form>
                </div>
            </div>
        </Skeleton>
    </div>
}