import { Sku } from '@/interface/sku';
import './index.less';
import { useEffect, useState, useRef, forwardRef } from 'react';
import { message, Modal, Table, Image, Button } from 'antd';
import { addSku, deleteSkus, getSkus, updateSku } from '@/api/sku';
import TnModal from '@/common/modal';
import { findStatusText, SkuStatusEnum, SkuStatusList, SpuStatusEnum } from '@/enums/sku-status';
import SkuEditPanel from './edit';
import { CategoryEnumText } from '@/enums/category';
import dayjs from 'dayjs';
import { ClientType } from '@/enums/client-type';

const SkuEditPage = forwardRef(SkuEditPanel);

export default function SkuPanel() {
    const skuEditRef = useRef(null);

    const [isShowEdit, setIsShowEdit] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false)   // 加载状态
    const [selectedSkus, setSelectedSkus] = useState<Sku[]>([])   // 选择的商品列表
    const [skus, setSkus] = useState<Sku[]>([])   // 商品列表
    const [displaySkus, setDisplaySkus] = useState<Sku[]>([])   // 展示的商品列表
    const [editSku, setEditSku] = useState<Sku>(null)   // 正在编辑的商品

    const [sorterOrder, setSorterOrder] = useState({})   // 排序顺序
    const [filteredValue, setFilteredValue] = useState([]);

    useEffect(() => {
        generateData();
    }, [])

    useEffect(() => {
        setDisplaySkus(skus)
    }, [skus])

    const generateData = () => {
        getSkus({ clientType: ClientType.WEB }).then(res => {
            setSkus(res.data || []);
        }).catch(err => {
            message.error("获取商品列表失败");
        })
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
        setSelectedSkus(selectedRows);
    }

    const onClickDelete = () => {
        if (selectedSkus.length === 0) {
            message.warning("请选择要删除的商品");
            return;
        }
        TnModal().deleteModal({
            title: '确认删除',
            onOk() {
                Modal.destroyAll();
                setLoading(true);
                deleteSkus(selectedSkus.map(sku => sku.id)).then(res => {
                    message.success("删除成功");
                    setSelectedSkus([]);
                    generateData();
                }).catch(err => {
                    message.error("删除失败");
                }).finally(() => {
                    setLoading(false);
                })
            },
            onCancel() {
                Modal.destroyAll();
            },
        });
    }

    const onClickOk = async () => {
        if (loading) return;
        if (!skuEditRef.current) return;
        const form = skuEditRef.current.form;
        const editSku = skuEditRef.current.editSku;
        const mainFile = skuEditRef.current.mainFile;
        const detailFiles = skuEditRef.current.detailFiles;
        const bannerFiles = skuEditRef.current.bannerFiles;
        const newDetails = [...detailFiles];
        for (let i = 0; i < newDetails?.length; i++) {
            if (i === 0 && !newDetails[0]?.orderNum) {
                newDetails[0].orderNum = 1;
            }
            if (i === 0) {
                continue;
            }
            newDetails[i].orderNum = newDetails[i - 1].orderNum + 1;
        }
        for (let i = 0; i < bannerFiles?.length; i++) {
            if (i === 0 && !bannerFiles[0]?.orderNum) {
                bannerFiles[0].orderNum = 1;
            }
            if (i === 0) {
                continue;
            }
            bannerFiles[i].orderNum = bannerFiles[i - 1].orderNum + 1;
        }

        newDetails.push(...bannerFiles);
        try {
            const values = await form.validateFields();
            let newSku = { ...values };
            if (mainFile == null) {
                message.warning("请上传主图");
                return;
            }
            if (newSku.spus == null || newSku.spus.length === 0) {
                message.warning("请至少添加一个规格");
                return;
            }
            setLoading(true);
            newSku = { ...editSku, ...newSku, details: newDetails, originFile: mainFile, banners: [], url: mainFile.url };
            newSku.params?.forEach((item, idx) => item.orderNum = idx + 1)

            if (editSku.id == null) {
                newSku.status = SkuStatusEnum.ON;
                let stock = 0;
                let minPrice = Number.MAX_VALUE;
                const spus = newSku.spus;
                spus.forEach(spu => {
                    if (spu.status === SpuStatusEnum.ON || spu.id == null) {
                        stock += spu.stock;
                        minPrice = Math.min(minPrice, Number(spu.price.toFixed(2)));
                    }
                })
                newSku.status = SkuStatusEnum.OFF;
                newSku.stock = stock;
                newSku.price = minPrice;
                newSku.url = mainFile.url;
                addSku(newSku).then(res => {
                    message.success("添加成功");
                    newSku.id = res.data;
                    setSkus([newSku, ...skus]);
                    setEditSku(null);
                    setIsShowEdit(false);
                }).catch(err => {
                    message.error("添加失败");
                }).finally(() => {
                    setLoading(false);
                })
            } else {
                newSku.id = editSku.id;
                updateSku(newSku.id, newSku).then(res => {
                    message.success("修改成功");
                    // 重新计算库存和价格
                    setSkus(skus.map(sku => {
                        if (sku.id === newSku.id) {
                            const spus = newSku.spus;
                            let stock = 0;
                            let minPrice = Number.MAX_VALUE;
                            if (spus == null || spus.length === 0) {
                                stock = 0;
                                minPrice = 0;
                            } else {
                                spus.forEach(spu => {
                                    if (spu.status === SpuStatusEnum.ON || spu.id === null) {
                                        stock += spu.stock;
                                        minPrice = Math.min(minPrice, Number(spu.price.toFixed(2)));
                                    }
                                })
                            }
                            newSku.stock = stock;
                            newSku.price = Number(minPrice.toFixed(2));
                            return newSku;
                        } else {
                            return sku;
                        }
                    }));
                    setEditSku(null);
                    setIsShowEdit(false);
                }).catch(err => {
                    message.error("修改失败");
                }).finally(() => {
                    setLoading(false);
                })
            }
        } catch {
            return;
        }
    }

    const onDownSku = (sku: Sku, isDown = true) => {
        setLoading(true);
        const newSku = { id: sku.id, status: isDown ? SkuStatusEnum.OFF : SkuStatusEnum.ON };
        updateSku(sku.id, newSku).then(res => {
            message.success(isDown ? '下架成功' : '上架成功');
            const idx = skus.findIndex(item => item.id === sku.id);
            const newSkus = [...skus];
            newSkus[idx] = { ...sku, ...newSku };
            setSkus([...newSkus])
        }).catch(err => {
            message.error(isDown ? '下架失败' : '上架失败');
        }).finally(() => {
            setLoading(false);
        })
    }


    return (
        <div className='sku-panel'>
            <div className='sku-panel-title'>
                {/* <span style={{ color: isShowEdit ? 'rgba(0, 0, 0, 0.45)' : '#000', cursor: 'pointer' }}
                    onClick={() => setIsShowEdit(false)}>商品管理</span>
                {isShowEdit && <span style={{ cursor: 'default' }}>/</span>}
                {isShowEdit && <span style={{ cursor: 'pointer' }}>商品信息</span>} */}
                {!isShowEdit && <div className='sku-panel-title-right'>
                    <div className='sku-pannel-title-btn' onClick={() => {
                        setIsShowEdit(true);
                        setEditSku(null);
                    }}>添加新商品</div>
                    <div className='sku-pannel-title-btn sku-pannel-title-btn-cancel' onClick={onClickDelete}>批量下架</div>
                </div>}
                {isShowEdit && <div className='sku-panel-title-right'>
                    <div className='sku-pannel-title-btn sku-pannel-title-btn-cancel' onClick={() => {
                        setIsShowEdit(false);
                        setEditSku(null);
                    }}>取消</div>
                    <Button className='sku-pannel-title-btn' onClick={onClickOk} loading={loading}>{`确认 `}</Button>
                </div>}
            </div>
            {
                !isShowEdit && <div className='sku-panel-content'>
                    {/* <div className='sku-ope-area'>
                        <div className='sku-ope-area-left' onClick={() => {
                            setIsShowEdit(true);
                            setEditSku(null);
                        }}>添加新商品</div>
                        <div className='sku-ope-area-right'>
                            <div className='sku-ope-area-right-tip'>
                                {selectedSkus.length > 0 ? `已选${selectedSkus.length}` : ''}
                            </div>
                            <div className='sku-ope-area-right-btn' onClick={onClickDelete}>批量下架</div>
                        </div>
                    </div> */}
                    <div className='sku-panel-content-table'>
                        <Table
                            loading={loading}
                            pagination={false}
                            sticky
                            className="common-table"
                            bordered={false}
                            onChange={(e, filter, sorter) => {
                                setFilteredValue(filter.status)
                                const column = sorter['column'];
                                const sorterOrder = {};
                                if (column != null) {
                                    const dataIndex = column['dataIndex'];
                                    sorterOrder[dataIndex] = sorter['order'];
                                }
                                setSorterOrder(sorterOrder);
                            }}
                            dataSource={displaySkus}
                            rowKey="id"
                            rowSelection={{ onChange: onSelectChange }}
                        >
                            <Table.Column title="商品封面图" dataIndex="" width='10%'
                                render={(text, record: Sku) => {
                                    return <Image src={record.url} width={70} height={70} />
                                }} />
                            <Table.Column title="商品分类" dataIndex="" width='6%'
                                render={(text, record: Sku) => {
                                    return <div>{CategoryEnumText[record.categoryId] || '其他'}</div>
                                }} />
                            <Table.Column title="商品上架/下架时间" dataIndex="gmtCreate" width='12%'
                                sortOrder={sorterOrder['gmtCreate']}
                                sorter={(a: Sku, b: Sku) => a.statusTime - b.statusTime}
                                render={(text, record: Sku) => {
                                    return <div>{dayjs(record.statusTime).format("YYYY-MM-DD HH:mm:ss")}</div>
                                }} />
                            <Table.Column title="商品名称" dataIndex="name" ellipsis />
                            <Table.Column title="规格" dataIndex="" width='10%'
                                render={(text, record: Sku) => {
                                    return <div className='ellipsis'>{record?.spus[0]?.name || ''}</div>
                                }}
                            />
                            <Table.Column title="价格(元)" dataIndex="price" width='10%'
                                sortOrder={sorterOrder['price']}
                                sorter={(a: Sku, b: Sku) => a.price - b.price}
                                render={(text, record: Sku) => {
                                    return <div>{text.toFixed(2)}</div>;
                                }} />
                            <Table.Column title="库存" dataIndex="stock" width='10%'
                                sortOrder={sorterOrder['stock']}
                                sorter={(a: Sku, b: Sku) => a.stock - b.stock}
                                render={(text, record: Sku) => {
                                    return <div>{record.stock}</div>
                                }} />
                            <Table.Column title="状态" dataIndex="status" width='10%'
                                filteredValue={filteredValue}
                                filters={SkuStatusList}
                                onFilter={(value, record: Sku) => record.status === value} render={(text, record: Sku) => {
                                    return <div className='common-table-status'>{findStatusText(record.status)}</div>
                                }} />
                            <Table.Column title="操作" dataIndex="" width="12%" render={(text, record: Sku) => {
                                return (<div className='common-table-action'>
                                    <div onClick={() => { setEditSku(record); setIsShowEdit(true) }}>编辑商品</div>
                                    {record.status == SkuStatusEnum.ON && <div onClick={() => onDownSku(record)}>下架商品</div>}
                                    {record.status == SkuStatusEnum.OFF && <div onClick={() => onDownSku(record, false)}>上架商品</div>}
                                </div>
                                )

                            }} />
                        </Table></div>

                </div>
            }
            {isShowEdit && <SkuEditPage ref={skuEditRef} skuId={editSku?.id} />}
        </div >
    )
}