import { User } from "@/interface/user";

export const GLOBAL_NAMESPACE = 'tncet_shop.';
export const AUTHORIZATION = GLOBAL_NAMESPACE + 'authorization';
export const REMEMBER_USERNAME = GLOBAL_NAMESPACE + 'username';
export const REMEMBER_PASSWORD = GLOBAL_NAMESPACE + 'password';
export const REMEMBER_TAG = GLOBAL_NAMESPACE + 'remember';
export const USER_META = GLOBAL_NAMESPACE + 'user_meta';

export const USER_UUID = GLOBAL_NAMESPACE + 'user_uuid';

class LocalStore {

    public get(key: string) {
        return JSON.parse(localStorage.getItem(key) || 'null') || null;
    }

    public set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public remove(key: string): void {
        localStorage.removeItem(key);
    }

    public getUser() {
        return this.get(USER_META);
    }

    public setUser(value: User) {
        return this.set(USER_META, value);
    }

    get token(): string {
        return this.get(AUTHORIZATION) || '';
    }

    set token(value: string) {
        this.set(AUTHORIZATION, value);
    }

    get password(): string {
        return this.get(REMEMBER_PASSWORD) || '';
    }
    set password(value: string) {
        this.set(REMEMBER_PASSWORD, value);
    }

    get userUuid(): string {
        return this.get(USER_UUID) || '';
    }
    set userUuid(value: string) {
        this.set(USER_UUID, value);
    }

    clear() {
        this.remove(AUTHORIZATION);
        this.remove(USER_UUID);
    }
}

export default new LocalStore();