import { AxiosResponse } from "axios";
import { axios } from "@/config/axios"
import { Order } from "@/interface/order";

export function getOrders(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get("/orders", { params: params });
}

export function getOrder(orderId: string, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/orders/${orderId}`, { params: params });
}

export function updateOrder(id: string, order: Order, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/orders/${id}`, order, { params: params });
}


export function getOrderDateStatistic(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/orders/date-statistic`, { params: params });
}

export function getOrderCategoryStatistic(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/orders/category-statistic`, { params: params });
}

export function getOrderCount(data, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post("/orders/count", data, { params: params });
}

export function getAfterSaleCount(data, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post("/after-sales/count", data, { params: params });
}

export function downloadShippingOrder(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/orders/shipping-order`, {
        params: params,
        responseType: 'blob',
    })
}

export function downloadBillFlow(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/orders/bill-flow`, {
        params: params,
        responseType: 'blob',
    })
}