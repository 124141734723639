export const SkuStatus = {
    ON: "销售中",
    OFF: "已下架",
}

export const SkuStatusEnum = {
    ON: 1,
    OFF: 2,
}

export const SkuStatusList = [
    { text: '销售中', value: SkuStatusEnum.ON },
    { text: '已下架', value: SkuStatusEnum.OFF },
]

export const findStatusText = (status: number) => {
    // 根据数值获取对应的键
    const statusKey = Object.keys(SkuStatusEnum).find(key => SkuStatusEnum[key] === status);
    // 使用该键查找 SkuStatus 中的文本
    return statusKey ? SkuStatus[statusKey] : '未知状态';
};

export const SpuStatus = {
    ON: "上架",
    OFF: "下架",
}

export const SpuStatusEnum = {
    ON: 1,
    OFF: 2,
}
