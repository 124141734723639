import { useEffect, useImperativeHandle, useState } from 'react';
import './index.less';
import { getOrder } from '@/api/order';
import { Order } from '@/interface/order';
import { Image, Input, message, Skeleton } from 'antd';
import { OrderStatus, OrderStatusText } from '@/enums/order-status';
import { OrderItemStatus } from '@/enums/order-item-status';

interface IProps {
    orderId: string;
}

export default function OrderEditPanel(props: IProps, ref) {

    const { orderId } = props;

    const [order, setOrder] = useState<Order>(null);
    const [loading, setLoading] = useState(false);

    const [logisticsNumber, setLogisticsNumber] = useState<string>('');
    const [logisticsName, setLogisticsName] = useState<string>('');

    useEffect(() => {
        generateData();
    }, [orderId])

    useImperativeHandle(ref, () => ({
        logisticsNumber: logisticsNumber,
        detailOrder: order
    }));

    const generateData = () => {
        setLoading(true);
        getOrder(orderId).then(res => {
            const order = res.data;
            const orderItems = order.orderItems || [];
            setOrder(res.data);
            setLogisticsNumber(orderItems[0].logisticsNumber || '');
            setLogisticsName(orderItems[0].logisticsComName || '');
        }).catch(err => {
            message.error("获取订单详情失败");
        }).finally(() => {
            setLoading(false);
        })
    }


    return <div className='order-edit-panel'>
        <Skeleton loading={loading}>
            <div className='order-edit-list'>
                <div className='order-edit-row'>
                    <div className='order-edit-row-name'>订单编号</div>
                    <div className='order-edit-row-value'>{order?.id}</div>
                </div>
                <div className='order-edit-row'>
                    <div className='order-edit-row-name'>快递公司</div>
                    <div className='order-edit-row-value'>{logisticsName}
                    </div>
                </div>
                <div className='order-edit-row'>
                    <div className='order-edit-row-name'>快递单号</div>
                    <div className='order-edit-row-value'>{logisticsNumber}
                        {/* <Input
                            value={logisticsNumber}
                            placeholder='已发货可以在此填写快递单号'
                            className='logistics-number-input'
                            onChange={(e) => setLogisticsNumber(e.target.value)}
                            disabled={!(order?.status === OrderStatus.SUCCESS || order?.status === OrderStatus.WAITING_RECEIVE)}
                        /> */}
                    </div>
                </div>
                <div className='order-edit-row'>
                    <div className='order-edit-row-name'>收货人</div>
                    <div className='order-edit-row-value'>{order?.fullName}</div>
                </div>
                <div className='order-edit-row'>
                    <div className='order-edit-row-name'>收货人联系方式</div>
                    <div className='order-edit-row-value'>{order?.tel}</div>
                </div>
                <div className='order-edit-row'>
                    <div className='order-edit-row-name'>收货地址(省)</div>
                    <div className='order-edit-row-value'>{order?.province}</div>
                </div>
                <div className='order-edit-row'>
                    <div className='order-edit-row-name'>收货地址(市)</div>
                    <div className='order-edit-row-value'>{order?.city}</div>
                </div>
                <div className='order-edit-row'>
                    <div className='order-edit-row-name'>收货地址(县/区)</div>
                    <div className='order-edit-row-value'>{order?.county}</div>
                </div>
                <div className='order-edit-row'>
                    <div className='order-edit-row-name'>详细地址</div>
                    <div className='order-edit-row-value'>{order?.specificLocation}</div>
                </div>
                <div className='order-edit-row'>
                    <div className='order-edit-row-name'>备注</div>
                    <div className='order-edit-row-value'>{order?.remark}</div>
                </div>
                <div className='order-edit-row'>
                    <div className='order-edit-row-name'>订单状态</div>
                    <div className='order-edit-row-value'>{OrderStatusText[order?.status]}</div>
                </div>
                <div className='order-edit-row'>
                    <div className='order-edit-row-name'>商品列表</div>
                    <div className='order-edit-row-value order-edit-row-list'>
                        {order?.orderItems.map(item => {
                            return <div className='order-edit-row-list-item' key={item.id}>
                                <div className='order-detail-sku-img'>
                                    <Image src={item.url} width={75} height={78} />
                                </div>
                                <div className='order-detail-sku-content'>
                                    <div className='order-detail-sku-row'>
                                        <div className='order-detail-sku-row-label'>名称</div>
                                        <div className='order-detail-sku-row-value'>{item.skuName}</div>
                                    </div>
                                    <div className='order-detail-sku-row'>
                                        <div className='order-detail-sku-row-label'>规格</div>
                                        <div className='order-detail-sku-row-value'>{item.spuName}</div>
                                    </div>
                                    <div className='order-detail-sku-row'>
                                        <div className='order-detail-sku-row-label'>单价</div>
                                        <div className='order-detail-sku-row-value'>{item.price}</div>
                                    </div>
                                    <div className='order-detail-sku-row'>
                                        <div className='order-detail-sku-row-label'>数量</div>
                                        <div className='order-detail-sku-row-value'>{item.count}</div>
                                    </div>
                                    {item?.status !== OrderItemStatus.NORMAL && <div className='order-detail-sku-row'>
                                        <div className='order-detail-sku-row-label'>状态</div>
                                        {item?.status === OrderItemStatus.AFTER_SALE && <div className='order-detail-sku-row-value' style={{ color: 'orange' }}>售后中</div>}
                                        {item?.status === OrderItemStatus.AFTER_SALE_FINISH && <div className='order-detail-sku-row-value' style={{ color: '#00a34f' }}>已退款</div>}
                                    </div>}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </Skeleton>
    </div>
}