
export const CategoryEnum = {
    ALL: -1,
    MILLET: 1,
    MATURE_VINEGAR: 2,
    OTHER: 3,
}

export const CategoryEnumText = {
    [CategoryEnum.ALL]: '全部',
    [CategoryEnum.MILLET]: '小米',
    [CategoryEnum.MATURE_VINEGAR]: '陈醋',
    [CategoryEnum.OTHER]: '其他',
}

export const CategoryEnumOptions = [
    {
        label: CategoryEnumText[CategoryEnum.ALL],
        value: CategoryEnum.ALL,
    },
    {
        label: CategoryEnumText[CategoryEnum.MILLET],
        value: CategoryEnum.MILLET,
    },
    {
        label: CategoryEnumText[CategoryEnum.MATURE_VINEGAR],
        value: CategoryEnum.MATURE_VINEGAR,
    },
    {
        label: CategoryEnumText[CategoryEnum.OTHER],
        value: CategoryEnum.OTHER,
    }
]