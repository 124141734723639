import React, { useEffect, useState } from 'react'
import './index.less'
import { ReactComponent as UserSvg } from '@/icons/user.svg';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import MerchantPanel from '@/routes/merchant';
import OrderPanel from '@/routes/order';
import AfterSalePanel from '@/routes/after-sale';
import SkuPanel from '@/routes/sku';
import UserPanel from '@/routes/user';
import { LeftMenu, LeftMenus } from '@/enums/left-menu';
import classNames from 'classnames';
import SaleStatisticPanel from '@/routes/sale-statistic';
import { Tooltip, Image } from 'antd';
import localStore from '@/store/local-store';
import { OrderStatus } from '@/enums/order-status';
import { getAfterSaleCount, getOrderCount } from '@/api/order';
import { AfterSaleStatus } from '@/enums/after-sale';

export default function MainLayout() {
    //管理訂單數量
    const [orderCount, setOrderCount] = useState(0);
    //管理售后數量
    const [afterSaleCount, setAfterSaleCount] = useState(0);

    const navigate = useNavigate();
    const location = useLocation();
    const [hoverMenu, setHoverMenu] = useState<number>(null);
    const [currentMenu, setCurrentMenuKey] = useState<number>(null);

    useEffect(() => {
        const path = location.pathname;
        const option = LeftMenus.find(menu => path.startsWith(menu.url));
        if (!!option) {
            setCurrentMenuKey(option.key);
        }
        fetchOrderCount(); // 获取最新订单数量
        fetchafterSaleCount();
        // 定时轮询获取订单数量
        const interval = setInterval(() => {
            fetchOrderCount(); // 获取最新订单数量
            fetchafterSaleCount();//获取最新售後数量
        }, 10000); // 每 5 秒刷新一次

        return () => clearInterval(interval); // 清除定时器
    }, [])

    const fetchOrderCount = () => {
        const statusList = [OrderStatus.SUCCESS]; // 需要根据后端定义传入
        const needOrigin = false; // 根据需求设置为 true 或 false

        getOrderCount({ statusList: statusList, needOrigin: needOrigin, searchSelf: false }).then((response) => {
            // 假设返回的数据是 { data: count }
            setOrderCount(response.data); // 更新前端的状态
        }).catch((error) => {
            console.error('获取订单数量失败:', error);
        });

    };

    const fetchafterSaleCount = () => {
        const statusList = [AfterSaleStatus.APPLY, AfterSaleStatus.DOING]; // 需要根据后端定义传入
        const needOrigin = false; // 根据需求设置为 true 或 false

        getAfterSaleCount({ statusList: statusList, needOrigin: needOrigin, searchSelf: false }).then((response) => {
            // 假设返回的数据是 { data: count }
            setAfterSaleCount(response.data); // 更新前端的状态
        }).catch((error) => {
            console.error('获取售后数量失败:', error);
        });

    };

    const onClickSideMenu = (option) => {
        setCurrentMenuKey(option.key);
        navigate(option.url);
    }

    const logout = () => {
        localStore.clear();
        navigate('/login');
    }


    return (
        <div className="main-content">
            {/* <div className='backend-header'>
                <div className="user-group">
                    <div className='user-group-icon'><UserSvg /></div>
                    <Tooltip title="退出登录">
                        <div className='user-group-text' onClick={() => logout()}>{localStore?.getUser()?.mobile || ''}</div>
                    </Tooltip>
                </div>
            </div> */}
            <div className='backend-container'>
                <div className='left-menu'>
                    <div className="left-logo-group">
                        <div className='small-icon'></div>
                        <div className='big-text'>耕朴源</div>
                    </div>
                    {
                        LeftMenus.map(menu => (
                            <div
                                onMouseEnter={() => setHoverMenu(menu.key)}
                                onMouseLeave={() => setHoverMenu(null)}
                                onClick={() => onClickSideMenu(menu)}
                                key={menu.key}
                                className={classNames({ 'option': true, 'selected': currentMenu == menu.key })}
                            >
                                {hoverMenu !== menu.key && currentMenu !== menu.key && <menu.normalIcon />}
                                {hoverMenu === menu.key && currentMenu !== menu.key && <menu.hoverIcon />}
                                {currentMenu === menu.key && <menu.activeIcon />}
                                <div className='right-text'>{menu.name}</div>
                                {menu.key == LeftMenu.order && orderCount > 0 && (
                                    <div className='corner-icon'> {orderCount}
                                    </div>
                                )}
                                {menu.key == LeftMenu.afterSale && afterSaleCount > 0 && (
                                    <div className='corner-icon'> {afterSaleCount}
                                    </div>
                                )}
                            </div>
                        ))
                    }
                    <div className="user-group">
                        <div className='user-group-icon'><UserSvg /></div>
                        <Tooltip title="退出登录">
                            <div className='user-group-text' onClick={() => logout()}>{localStore?.getUser()?.petName || '店铺用户'}</div>
                        </Tooltip>
                    </div>
                </div>
                <div className='right-panel'>
                    <div className='main-route'>
                        <Routes>
                            <Route path='/shop' element={<MerchantPanel />} />
                            <Route path='/order' element={<OrderPanel />} />
                            <Route path='/sku' element={<SkuPanel />} />
                            <Route path='/user' element={<UserPanel />} />
                            <Route path='/after-sale' element={<AfterSalePanel />} />
                            <Route path='/sale-statistic' element={<SaleStatisticPanel />} />
                            <Route path="*" element={<Navigate to="/shop" />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    )
}