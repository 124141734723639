import './index.less';
import { useEffect, useState, useRef } from 'react';


interface IProps {

}

export default function SaleStatisticPanel(props: IProps) {


    return (
        <div className='sale-statistic-panel'>
            <div className='statistic-block'>
                <div className='statistic-data-info'>店铺数据</div>
                <div className='statistic-data-row'>
                    <div className='statistic-data-block'>
                        <div className='statistic-data-first'>日销售额</div>
                        <div className='statistic-data-second'>108,885</div>
                        <div className='statistic-data-third'>
                            <span>较昨日</span>
                            <span>116.75↑</span>
                        </div>
                    </div>
                    <div className='statistic-data-block'>
                        <div className='statistic-data-first'>周销售额</div>
                        <div className='statistic-data-second'>108,885</div>
                        <div className='statistic-data-third'>
                            <span>较昨日</span>
                            <span>116.75↑</span>
                        </div>
                    </div>
                    <div className='statistic-data-block'>
                        <div className='statistic-data-first'>月销售额</div>
                        <div className='statistic-data-second'>108,885</div>
                        <div className='statistic-data-third'>
                            <span>较昨日</span>
                            <span>116.75↑</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}