import { AxiosResponse } from "axios";
import { axios } from "@/config/axios"
import { User } from "@/interface/user";

export function getUserByLogin(mobile: string, password: string, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/users/pwd-login`, null, {
        params: {
            ...params,
            mobile: mobile,
            password: password,
        }
    })
}

export function getUsersCheckLogin(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/users/check-login`, {
        params: {
            ...params,
        }
    })
}

export function deleteUsers(ids: number[], params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/users/delete-multi`, ids, {
        params: {
            ...params,
        }
    })
}

export function addUser(user: User, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/users`, user, {
        params: {
            ...params,
        }
    })
}

export function getUsers(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/users`, {
        params: {
            ...params,
        }
    })
}


export function updateUser(uuid: string, user: User, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/users/${uuid}`, {
        params: {
            ...params,
        }
    })
}

export function existUser(params: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/users/exist`, {
        params: {
            ...params,
        }
    })
}