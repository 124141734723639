import { AxiosResponse } from "axios";
import { axios } from "@/config/axios"

// 微信方物流
export function getWeChatLogisticsCompanies(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get("/wechat-logistics-companies", { params: params });
}

// 快递100物流（废弃）
export function getLogisticsComs(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get("/logistics-companies", { params: params });
}