export const AfterSaleType = {
    REFUND: 1,
    RETURN_AND_REFUND: 2,
}

export const AfterSaleTypeText = {
    [AfterSaleType.REFUND]: '退款',
    [AfterSaleType.RETURN_AND_REFUND]: '退货退款',
}

export const AfterSaleTypeList = [
    { text: '退款', value: AfterSaleType.REFUND },
    { text: '退货退款', value: AfterSaleType.RETURN_AND_REFUND },
]

export const AfterSaleStatus = {
    APPLY: 1,
    REJECT: 2,
    DOING: 3,
    REFUSE: 4,
    FINISH: 5,
    CANCEL: 6,
}

export const AfterSaleStatusText = {
    [AfterSaleStatus.APPLY]: '待商家审核',
    [AfterSaleStatus.REJECT]: '商家拒绝申请',
    [AfterSaleStatus.DOING]: '待寄回商品',
    [AfterSaleStatus.REFUSE]: '拒收退货快递',
    [AfterSaleStatus.FINISH]: '售后已完成',
    [AfterSaleStatus.CANCEL]: '已撤回申请',
}

export const AfterSaleStatusList = [
    { text: '待商家审核', value: AfterSaleStatus.APPLY },
    { text: '商家拒绝申请', value: AfterSaleStatus.REJECT },
    { text: '待寄回商品', value: AfterSaleStatus.DOING },
    { text: '拒收退货快递', value: AfterSaleStatus.REFUSE },
    { text: '售后已完成', value: AfterSaleStatus.FINISH },
    { text: '已撤回申请', value: AfterSaleStatus.CANCEL },
]