import './index.less';
import { ReactComponent as Logo } from './img/logo.svg'
import { useEffect, useRef, useState } from 'react';
import Merchant from '@/interface/merchant';
import { getMerchant } from '@/api/merchant';
import { Carousel, message, Image } from 'antd';
import { getOrderCategoryStatistic, getOrderDateStatistic } from '@/api/order';
import { OrderStatistic, TimeValue } from '@/interface/order';
import EditMerchantPanel from './edit';
import SaleChart from './chart';
import dayjs from 'dayjs';
import { CategoryEnum, CategoryEnumOptions } from '@/enums/category';
import classNames from 'classnames';
import { PhoneOutlined, EnvironmentOutlined, ShopOutlined } from '@ant-design/icons';

export interface ChartValue {
    name: string
    data: TimeValue[]
}


export default function MerchantPanel() {
    const [merchant, setMerchant] = useState<Merchant>(null); // 店铺信息
    const [editing, setEditing] = useState<boolean>(false); // 编辑店铺
    const [statistic, setStatistic] = useState<OrderStatistic>(null); // 统计信息

    // const [chartSeries, setChartSeries] = useState<OrderStatistic>(null); // 图标series
    // const [chartSeries, setChartSeries] = useState<OrderStatistic>(null); // 图标series
    const [qData, setQData] = useState<ChartValue[]>(null); // 图标series

    const [dateRange, setDateRange] = useState<{ start: dayjs.Dayjs, end: dayjs.Dayjs }>(null) // 时间范围
    const chartRef = useRef(null);
    const containerRef = useRef(null);
    const [chartWidth, setChartWidth] = useState<number>(0); // 表格长
    const [chartHeight, setChartHeight] = useState<number>(0); // 表格宽

    const [contentWidth, setContentWidth] = useState<number>(0); // 表格长
    const [contentHeight, setContentHeight] = useState<number>(0); // 表格宽
    const [activeCategory, setActiveCategory] = useState<number>(CategoryEnum.ALL); // 分类

    useEffect(() => {
        if (!chartRef.current) {
            setChartWidth(0);
            setChartHeight(0);
            return;
        }
        setChartWidth(chartRef.current.clientWidth);
        setChartHeight(chartRef.current.clientHeight);
    }, [chartRef.current])

    useEffect(() => {
        if (!containerRef.current) {
            setContentWidth(0);
            setContentHeight(0);
            return;
        }
        setContentWidth(containerRef.current.clientWidth);
        setContentHeight(containerRef.current.clientHeight);
    }, [chartRef.current])

    useEffect(() => {
        generateMerchantData();
    }, [])

    useEffect(() => {
        generateDateStatisticData();
    }, [])

    useEffect(() => {
        // TODO
        generateCategoryStatisticData();
    }, [dateRange, activeCategory])


    const generateMerchantData = () => {
        // 只有一条数据 默认为1
        getMerchant(1).then(res => {
            if (res.status === 200) {
                let data: Merchant = res.data || null;
                setMerchant(data);
            }
        }).catch(err => {
            message.error("获取店铺信息失败");
        }).finally(() => {

        })
    }

    const generateDateStatisticData = () => {
        getOrderDateStatistic().then(res => {
            if (res.status === 200) {
                let data: OrderStatistic = res.data || null;
                setStatistic(data);
            }
        }).catch(err => {
            message.error("获取统计信息失败");
        }).finally(() => {
        })
    }

    const generateCategoryStatisticData = () => {
        let startTimeStamp = null;
        let endTimeStamp = null;
        if (!!dateRange) {
            startTimeStamp = dayjs(dateRange?.start).startOf('day').valueOf();
            endTimeStamp = dayjs(dateRange?.end).endOf('day').valueOf();
        }
        let par = {
            startTimeStamp: startTimeStamp,
            endTimeStamp: endTimeStamp,
        }
        getOrderCategoryStatistic(par).then(res => {
            if (res.status === 200) {
                let data: { key: number, value: TimeValue[] } = res.data || null;
                let milletData = data[CategoryEnum.MILLET];
                let matureVinegarData = data[CategoryEnum.MATURE_VINEGAR];
                let otherData = data[CategoryEnum.OTHER];

                let mq: ChartValue = {
                    name: "小米",
                    data: milletData
                }
                let vq: ChartValue = {
                    name: "陈醋",
                    data: matureVinegarData
                }
                let oq: ChartValue = {
                    name: "其他",
                    data: otherData
                }

                let qData = [mq, vq, oq];
                setQData(qData);
            }
        }).catch(err => {
            message.error("获取统计信息失败");
        }).finally(() => {
        })
    }

    return (
        <div className='merchant-container' ref={containerRef}>
            {!editing && <>
                <Carousel autoplay className='bg-area' style={{ width: contentWidth, height: 320 }}>
                    <div className='bg-item'></div>
                    <div className='bg-item2'></div>
                    <div className='bg-item3'></div>
                </Carousel>
                <div className='shop-block-area'>

                    <div className='shop-block'>
                        <div className='msg-area'>
                            <div className="addr-top"><PhoneOutlined />电话</div>
                            <div className="addr-bottom" title={merchant?.address || "待填写"}>{merchant?.tel || "待填写"}</div>
                        </div>
                        <div className='msg-area'>
                            <div className="addr-top"><EnvironmentOutlined /> 地址</div>
                            <div className="addr-bottom" title={merchant?.address || "待填写"}>{merchant?.address || "待填写"}</div>
                        </div>
                        <div className='msg-area' style={{ flex: 1 }}>
                            <div className="addr-top"><ShopOutlined />简介</div>
                            <div className="des-bottom" title={merchant?.address || "待填写"}>{merchant?.description || "待填写"}{merchant?.description || "待填写"}</div>
                        </div>
                    </div>
                    {/* <div className='shop-block'>
                        <div className='logo-area'>
                            <Logo />{merchant?.shopName || "待填写"}
                        </div>
                        <div className='divider'></div>
                        <div className='msg-area'>
                            <div className='msg-row'>
                                <div className='msg-col'>
                                    <div className="msg-top">名称</div>
                                    <div className="msg-bottom" title={merchant?.shopName || "待填写"}>{merchant?.shopName || "待填写"}</div>
                                </div>
                                <div className='msg-col'>
                                    <div className="msg-top">电话</div>
                                    <div className="msg-bottom">{merchant?.tel || "待填写"}</div>
                                </div>
                            </div>
                            <div className="addr-top">地址</div>
                            <div className="addr-bottom" title={merchant?.address || "待填写"}>{merchant?.address || "待填写"}</div>
                        </div>
                        <div className='discr-area'>
                            <div className='discr-info'>店铺描述:</div>
                            <div className='discr-discr' title={merchant?.description || "待填写"}>
                                {merchant?.description || "待填写"}
                            </div>
                        </div>
                        <div className='pic-area'>
                            <div className='pic-info'>店铺封面:</div>
                            <img className='pic-pic' src={merchant?.logoUrl} style={{ borderRadius: '15px' }} />
                        </div>
                        <div className='pic-area'>
                            <div className='pic-info'>营业执照:</div>
                            <img className='pic-pic' src={merchant?.licenseUrl} style={{ borderRadius: '15px' }} />
                        </div>
                    </div> */}
                    <div className='change-btn' onClick={() => setEditing(true)}>修改信息</div>
                </div>
                <div className='statistic-block'>
                    <div className='statistic-data-info'>店铺数据</div>
                    <div className='cards'>
                        <div className='category-area'>
                            {
                                CategoryEnumOptions.map(
                                    item =>
                                        <div
                                            className={classNames({
                                                "category-item": true,
                                                "category-item-active": item.value === activeCategory,
                                            })}
                                            onClick={() => setActiveCategory(item.value)}
                                        >
                                            {item.label}
                                        </div>
                                )
                            }
                        </div>
                        <div className='cards-chart-area' ref={chartRef}>
                            <SaleChart
                                q_data={qData || []}
                                setDateRange={setDateRange}
                                width={chartWidth}
                                height={chartHeight}
                                activeCategory={activeCategory}
                            />
                        </div>
                        <div className='statistic-data'>
                            <div className='statistic-data-row'>
                                <div className='statistic-data-block'>
                                    <div className='statistic-data-first'>今日销售额</div>
                                    <div className='statistic-data-second'>{statistic?.todaySales?.toLocaleString() || 0}</div>
                                    <div className='statistic-data-third'>
                                        <span>较昨日</span>
                                        {
                                            statistic?.daySalesDiff != undefined ?
                                                <span>
                                                    {statistic?.daySalesDiff?.toLocaleString()}
                                                    <span style={{ color: statistic?.daySalesDiff >= 0 ? 'red' : 'green' }}>
                                                        {statistic?.daySalesDiff >= 0 ? '↑' : '↓'}
                                                    </span>
                                                </span>
                                                : <span>---</span>
                                        }
                                    </div>
                                </div>
                                <div className='statistic-data-block'>
                                    <div className='statistic-data-first'>本月销售额</div>
                                    <div className='statistic-data-second'>{statistic?.thisMonthSales?.toLocaleString() || 0}</div>
                                    <div className='statistic-data-third'>
                                        <span>较上月</span>
                                        {
                                            statistic?.monthSalesDiff != undefined ?
                                                <span>
                                                    {statistic?.monthSalesDiff?.toLocaleString()}
                                                    <span style={{ color: statistic?.monthSalesDiff >= 0 ? 'red' : 'green' }}>
                                                        {statistic?.monthSalesDiff >= 0 ? '↑' : '↓'}
                                                    </span>
                                                </span>
                                                : <span>---</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='statistic-data-row'>
                                <div className='statistic-data-block'>
                                    <div className='statistic-data-first'>本季度销售额</div>
                                    <div className='statistic-data-second'>{statistic?.thisQuarterSales?.toLocaleString() || 0}</div>
                                    <div className='statistic-data-third'>
                                        <span>较上季度</span>
                                        {
                                            statistic?.quarterSalesDiff != undefined ?
                                                <span>
                                                    {statistic?.quarterSalesDiff?.toLocaleString()}
                                                    <span style={{ color: statistic?.quarterSalesDiff >= 0 ? 'red' : 'green' }}>
                                                        {statistic?.quarterSalesDiff >= 0 ? '↑' : '↓'}
                                                    </span>
                                                </span>
                                                : <span>---</span>
                                        }
                                    </div>
                                </div>
                                <div className='statistic-data-block'>
                                    <div className='statistic-data-first'>本年度销售额</div>
                                    <div className='statistic-data-second'>{statistic?.thisYearSales?.toLocaleString() || 0}</div>
                                    <div className='statistic-data-third'>
                                        <span>较去年</span>
                                        {
                                            statistic?.yearSalesDiff != undefined ?
                                                <span>
                                                    {statistic?.yearSalesDiff?.toLocaleString()}
                                                    <span style={{ color: statistic?.yearSalesDiff >= 0 ? 'red' : 'green' }}>
                                                        {statistic?.yearSalesDiff >= 0 ? '↑' : '↓'}
                                                    </span>
                                                </span>
                                                : <span>---</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
            {editing && <EditMerchantPanel
                merchant={merchant}
                show={editing}
                onClose={() => {
                    setEditing(false);
                }}
                onSave={() => {
                    setEditing(false);
                    generateMerchantData();
                }}
            />}
        </div>
    )
}
