export const OrderStatus = {
    NONE: 1,
    DURING: 2,
    FAILURE: 3,
    SUCCESS: 4,
    WAITING_RECEIVE: 6,
    RECEIVED: 7,
    CLOSE: 8,
    AFTER_SALE: 9  // 暂时用不到了.
}

export const OrderStatusText = {
    [OrderStatus.NONE]: '待支付',
    [OrderStatus.DURING]: '支付中',
    [OrderStatus.FAILURE]: '支付失败',
    [OrderStatus.SUCCESS]: '待发货',
    [OrderStatus.WAITING_RECEIVE]: '待收货',
    [OrderStatus.RECEIVED]: '已收货',
    [OrderStatus.CLOSE]: '已关闭',
    [OrderStatus.AFTER_SALE]: '售后中'

}

export const OrderStatusList = [
    { text: '待支付', value: OrderStatus.NONE },
    { text: '支付中', value: OrderStatus.DURING },
    { text: '支付失败', value: OrderStatus.FAILURE },
    { text: '待发货', value: OrderStatus.SUCCESS },
    { text: '待收货', value: OrderStatus.WAITING_RECEIVE },
    { text: '已收货', value: OrderStatus.RECEIVED },
    { text: '已关闭', value: OrderStatus.CLOSE },
    { text: '售后中', value: OrderStatus.AFTER_SALE },
]