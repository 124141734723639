export const UserType = {
    NORMAL: 2,
    ADMIN: 1,
    EMPLOYEE: 3,
}

export const UserTypeText = {
    [UserType.NORMAL]: '普通用户',
    [UserType.ADMIN]: '店主',
    [UserType.EMPLOYEE]: '店铺员工',
}