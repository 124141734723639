import { AxiosResponse } from "axios";
import { axios } from "@/config/axios"
import Merchant from "@/interface/merchant";


export function getMerchant(id: number): Promise<AxiosResponse<any>> {
    return axios.get(`/merchants/${id}`);
}

export function addMerchant(merchant: Merchant): Promise<AxiosResponse<any>> {
    return axios.post("/merchants", merchant);
}

export function deleteMerchant(id: number): Promise<AxiosResponse<any>> {
    return axios.post(`/merchants/${id}`);
}

export function updateMerchant(id: number, merchant: Merchant): Promise<AxiosResponse<any>> {
    return axios.put(`/merchants/${id}`, merchant);
}